import React, { useState,useEffect } from 'react';
import axios from 'axios'
import { gtbl, getLastLanguageSelected, setLangFromBrowser } from '../utils/commons';
import HeaderQdiner from './HeaderQdiner';

function Login(props) {
  const [username, setUsername] = useState("")
  const [lang, setLang] = useState(null)
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(undefined)
  const [message, setMessage] = useState("")
  const [recoverPassword, setRecoverPassword] = useState(false)
  const [clickedChangePassword, setClickedChangePassword] = useState(false)
  const [newUser, setNewUser] = useState(false)
  const [rePassword, setRePassword] = useState(null)
  const [isRegistrato, setIsRegistrato] = useState(false)
  const [acceptTerm, setAcceptTerm] = useState(false)
  const [newsletterAccept, setNewsletterAccept] = useState(false)

  function setMessageTimeOut(message,time){
    if(!time){
      time=5000
    }
    setMessage(message)
    if(time>0){
      setTimeout(() => {
        setMessage("")
      }, time);
    }
  }
  
  useEffect(() => {
    let last_lang = getLastLanguageSelected()
    if(!last_lang){
      let res_lang = setLangFromBrowser(navigator.language)
      setLang(res_lang)
    }
  }, [])

  function loginUser(e){
    e.preventDefault()
    setLoading(true)
    let head ={'username':username, 'password':password}
    axios.get('/api/v1/user/', { headers:head }).then((res)=>{
      localStorage.setItem("tk",res.data.token)
      setLoading(true)
      window.location.replace('/c/dashboard');
    }).catch((error)=>{
      setMessageTimeOut("Errore")
      setLoading(true)
    })
  }
  
  function createUser(e){
    e.preventDefault()
    if(password===rePassword){
      let data={username:username,password:password,newsletter:newsletterAccept,account_lang:lang === "it" || lang === "en" ? lang:"en"}
      axios.post('/api/v1/user/',data).then((res)=>{
        if(!res.data.error){
          setIsRegistrato(true)
        }
        setMessageTimeOut(res.data.msg,10000)
      })
    }else{
      setMessageTimeOut(gtbl("different_password"))
    }
  }

  function sendEmailRecoverPassword(e){
    e.preventDefault();
    setClickedChangePassword(!clickedChangePassword); 
    setMessageTimeOut(gtbl("completing_operation"),10000)
    let data={
      username:username,
      type:"request_change_password",
    }
    axios.put("/api/v1/user/",data).then((res)=>{
      if(res.data.msg){
        setMessageTimeOut(res.data.msg,0)
      }
    }).catch(()=>{
      setMessageTimeOut(gtbl("sameting_wront"))
      setClickedChangePassword(false)
    })
  }
  return (
      <>
        <div className="container login-container">
          <HeaderQdiner></HeaderQdiner>
          <div className="row padding-header h-100">

            <div className="col-md-6 login-form-2" style={{margin:"0 auto"}}>
              {!newUser ? 
              !recoverPassword ? 
                <>
                <h3>login</h3>
                <form onSubmit={(e)=>{loginUser(e)}}>
                    <div className="form-group">
                        <input autoComplete="off"  type="text" className="form-control" placeholder="Your Email *" value={username} onChange={(e)=>{setUsername(e.target.value)}} />
                    </div>
                    <div className="form-group">
                        <input autoComplete="off"  type="password" className="form-control" placeholder="Your Password *" value={password} onChange={(e)=>{setPassword(e.target.value)}} />
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="form-group">
                          <input autoComplete="off"  type="submit" className="btn btn-primary" style={{width:"100%"}} value="Login" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="form-group">
                          <span className="btn btn-primary" style={{width:"100%"}} onClick={(e)=>{e.preventDefault();setRecoverPassword(!recoverPassword)}}>{gtbl("recover_password")}</span>
                        </div>
                      </div>
                    </div>
                </form>
                </>
              :
              <>
                <h3>{gtbl("recover_password_frm")}</h3>
                <form onSubmit={(e)=>{sendEmailRecoverPassword(e)}}>
                    <div className="form-group">
                        <input autoComplete="off"  type="text" className="form-control" placeholder="Your Email *" value={username} onChange={(e)=>{setUsername(e.target.value)}} />
                    </div>
                    <div className="form-group">
                        {!clickedChangePassword ?
                          <input autoComplete="off" style={{width:"100%"}} hidden={clickedChangePassword} type="submit" className="btnSubmit btn btn-primary" value={gtbl("change_password")} />
                          :null}
                    </div>
                    <div className="form-group">
                        <span className="btn btn-primary" style={{width:"100%"}} value="Login" onClick={(e)=>{e.preventDefault();setRecoverPassword(!recoverPassword);setMessage("")}}>{gtbl("back_to_login")}</span>
                    </div>
                </form>
                </>
                :
                <>
                  <h3>{gtbl("registration")}</h3>
                    <form onSubmit={(e)=>{createUser(e)}}>
                        <div className="form-group">
                            <input autoComplete="off"  type="text" className="form-control" placeholder="Email *" value={username} onChange={(e)=>{setUsername(e.target.value)}} />
                        </div>
                        <div className="form-group">
                            <input autoComplete="off"  type="password" className="form-control" placeholder="Password *" value={password} onChange={(e)=>{setPassword(e.target.value)}} />
                        </div>
                        {!isRegistrato ?
                          <>
                            <div className="form-group">
                                <input autoComplete="off"  type="password" className="form-control" placeholder="Repeat Password *" value={rePassword} onChange={(e)=>{setRePassword(e.target.value)}} />
                            </div>
                            <div>
                              <input autoComplete="off"  type="checkbox" className="" value={newsletterAccept} onChange={()=>{setNewsletterAccept(!newsletterAccept)}} />
                              <span className={"right-checkbox"}>{gtbl("subscribe_newsletter")}<b>newsletter</b></span>
                            </div>
                            <div>
                              <input autoComplete="off"  type="checkbox" value={acceptTerm} onChange={()=>{setAcceptTerm(!acceptTerm)}} />
                              <span className={"right-checkbox"}><a href="/termsandconditions" target="_blank">{gtbl("accept_term_condition")}</a></span>
                            </div>
                            <div className="form-group" style={{marginTop:"20px"}}>
                                <input autoComplete="off"  style={{width:"100%"}} type="submit" disabled={!acceptTerm} className="btn btn-success" value={gtbl("submit")} />
                            </div>
                          </> 
                       :null}
                    </form>
                </>
                }
                {!newUser ? 
                  <button className="btn btn-secondary" style={{width:"100%"}} onClick={()=>{setNewUser(!newUser)}}>
                    <span style={{fontWeight:"bold",fontSize:"20px"}}>{gtbl("new_user")}</span>
                  </button>
                :
                  <a href="/c/login">
                    <button className="btn btn-secondary" style={{width:"100%"}} onClick={()=>{setNewUser(!newUser)}}>
                      {gtbl("back_to_login")}
                    </button>
                  </a>} 
                
                <div style={{marginTop:"15px",textAlign:"center"}}><a href="/">{gtbl("go_to_homepage")}</a></div>

                <div style={{textAlign:"center",paddingTop:"15px"}}>
                  {message}
                </div>
            </div>
          </div>
        </div>
     </>
  );
}

export default Login;
