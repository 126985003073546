import React, { useEffect, useState } from 'react';

function doNothing(e){e.preventDefault()}

function Plate(props) {
    const [showIngredients, setShowIngredients] = useState(false)
    const [showAll, setShowAll] = useState(false)
    
    useEffect(() => {
        setShowAll(props.showAll)
    }, [props.showAll])
    return (
        <div className="plate menu-flow" hidden={props.plate.status === "delete"} style={{borderBottom:"1px solid grey",textAlign:"left"}} onClick={(e)=>{props.plate.ingredients || props.plate.ingredients==="" ?  setShowIngredients(!showIngredients):doNothing(e)}}>
            <div className="row">
                <div className="col-9" style={{paddingRight:"3px"}}>
                    <span className={props.plate.strike ? "strike-line" : null}>{props.plate.name} {props.plate.asterisk ? "*":null}</span> 
                </div>
                <div className="col-3" style={{textAlign:"right",paddingLeft:"3px"}}>
                    {!props.isTastingMenu && props.plate.price ? (parseFloat(props.plate.price).toFixed(2)).toLocaleString() + " "+ props.currency : null}
                     {/* <i hidden={!props.plate.ingredients || props.plate.ingredients===""} style={{fontSize:"17px"}} className= {showIngredients || showAll ? "fas fa-angle-down" : "fas fa-angle-down fa-angle-down-rotate"}></i> */}
                </div>
                 {/* <div className={ showIngredients || showAll ? "menu-plate col-12 ingredients ingredients-active" : "menu-plate col-12 ingredients"}> */}
                 <div className={"menu-plate col-12 ingredients ingredients-active"}>
                    {props.plate.ingredients}
                </div> 
            </div>
        </div>
    );
}

export default Plate;