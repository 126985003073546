import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import placeholder from '../images/placeholder.png'
import PlaceContext from './Context/PlaceContext';
import CustomHelmet from '../utils/CustomHelmet';
import {gtbl,dayOfWeekAsString} from '../utils/commons'
import Header from './Header';

function DetailsResturant(props) {
    const context = useContext(PlaceContext)
    const [resturant, setResturant] = useState(context.state && context.state.profile ? context.state.profile : props.resturant)
    let showGoToMenu=(props.showGoToMenu && props.showGoToMenu===true ? true : false )
    const [backurl, setbackurl] = useState(props.backurl)
    useEffect(() => {
        if(context.state && context.state.profile){
            setResturant(context.state.profile)
        }
    }, [context.state])

    return (
        <div className="resturant-shell" style={{padding:0,margin:0}}>
            <CustomHelmet title={resturant.name}></CustomHelmet>
            <Header homeurl={"/"} isDashboard={props.isDashboard} resturant={resturant} backurl={backurl} backgroundcolor={""} color={""} image={resturant.image?resturant.image:placeholder}></Header>
            <div className="menu" style={{width:"100%",maxWidth:"450px",margin:"0 auto",paddingTop:"10px",paddingBottom:"10px"}}>
            <Link hidden={!showGoToMenu} to={{
                pathname: '/'+resturant.slug+'/menus',
                    resturant:resturant
                }} style={{textDecoration:"none"}}>
                    <div className="logo">
                        {resturant.image ? 
                            <img src={resturant.image} style={{width:"100%", margin:"0 auto"}} alt=""/>
                        : 
                            <img src={placeholder} alt=""/>}
                    </div>
                    <div className="resturant-details container" style={{paddingBottom:"10px"}}>
                        <div><span className="resturant-details-name">{resturant.name}</span></div>
                        <div><span className="resturant-details-subtitle">{resturant.subtitle}</span></div>
                        <div className="arrow-menu">
                        <span>&#10230;</span>
                        </div>
                    </div>
                </Link>
                <div hidden={showGoToMenu}>
                <div className="logo" style={{padding:"5px"}}>
                    {resturant.image ? 
                            <img src={resturant.image} style={{width:"100%", margin:"0 auto"}} alt=""/>
                        : 
                            <img src={placeholder} alt=""/>}
                    </div>
                    
                    <div className="resturant-details container" style={{paddingBottom:"10px"}}>
                        <span className="resturant-details-name">{resturant.name}</span>
                        <div><span className="resturant-details-subtitle">{resturant.subtitle}</span></div>
                    </div>
                </div>
                <div>
                    <div className="row" style={{paddingBottom:"10px"}} >
                        <div className="col-3" style={{textAlign:"right"}}>
                        {resturant.phone ? 
                            <i className="fas fa-phone-alt"></i>
                        :null}
                        </div>
                        <div className="col-9 resturant-detail" >
                            <span className="resturant-details-info">
                                <a href={"tel:"+resturant.prefix_phone+resturant.phone}>
                                    {resturant.prefix_phone && resturant.phone ? resturant.prefix_phone+" ":null}{resturant.phone}
                                </a>
                            </span>
                        </div>
                    </div>
                    <div className="row" style={{paddingBottom:"10px"}}>
                        <div className="col-3" style={{textAlign:"right"}}>    
                        {resturant.address || resturant.city ? 
                            <i className="fas fa-map-marker-alt"></i>
                        :null}
                        </div>
                        <div className="col-9  resturant-detail">
                            <span className="resturant-details-info">{resturant.address}</span> <br/>
                            <span className="resturant-details-info">{resturant.city} {resturant.province ? "("+resturant.province+")" : null}</span>
                        </div>
                    </div>
                    <div className="row" style={{paddingBottom:"10px"}}>
                        <div className="col-3" style={{textAlign:"right"}}>
                        {resturant.email ? 
                            <i className="far fa-envelope"></i>
                        :null}
                        </div>
                        <div className="col-9  resturant-detail" >
                            <span className="resturant-details-info">
                                <a href={"mailto:"+resturant.email}>
                                    {resturant.email}
                                </a>
                            </span>
                        </div>
                    </div>
                    
                    <div>
                        {resturant.close_for_holiday === true? 
                            <div>
                                <hr/>
                                {resturant.message_close_for_holiday.split("\n").map(function(item) {
                                    return (
                                        <>
                                        {item}
                                        <br/>
                                        </>
                                    )
                                })}  
                            </div>
                            :
                            <div>
                                {resturant.work_days && resturant.work_days.filter(x=>x.rest_day === true).length > 0 ? <hr/>:null}
                                {resturant.work_days &&  resturant.work_days.map((wd,i)=>{
                                    return(
                                        <div hidden={!wd.rest_day}  key={i}>
                                            <div className="row" style={{paddingBottom:"5px"}}>
                                                <div className="col-3" style={{textAlign:"right",fontSize:"14px"}}>    
                                                <span style={Number(wd.day) === Number(new Date().getDay())-1 ? {fontWeight:"bold"} : null}>{wd.nameOfDay && wd.nameOfDay !== "" ? wd.nameOfDay : gtbl(dayOfWeekAsString(wd.day))}</span> 
                                                </div>
                                                <div className="col-9 resturant-detail" >
                                                    <div style={{paddingLeft:"5px"}}>
                                                    {wd.start}{wd.start_pause && wd.end_pause ? "-"+wd.start_pause +" , "+ wd.end_pause+"-":"-"}{wd.end}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div>
        </div>
    </div>
    );
}

export default DetailsResturant;