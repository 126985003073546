import React,{useEffect} from 'react';
import { useState } from 'react';
import axios from 'axios'
import HeaderHomepage from '../HeaderHomepage';
import { gtbl } from '../../utils/commons';



function ConfirmAccount(props) {
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState("")
    useEffect(() => {
        let data={
            confirm_id:props.match.params.confirm_id,
            type:"confirm_account"
        }
        axios.put("/api/v1/user/",data).then((res)=>{
            if(res.data.msg){
                setLoading(false)
                setMessage(res.data.msg)
                setTimeout(() => {
                  window.location.href = "/c/dashboard/"
                }, 5000);
            }
        }).catch(()=>{
            setLoading(false)
            setMessage(gtbl("wrongconfirm"))
        })
    }, [])
    return (
        <div>
            <HeaderHomepage></HeaderHomepage>
            {loading ? 
            <div>{gtbl("loading")}</div>:
            <div style={{textAlign:"center"}}>{message}</div>}
        </div>
    );
}

export default ConfirmAccount;