import React from 'react'

export const initialState = {profile:null};

export const PlaceReducer = (state, action) => {
  switch (action.type) {
    case "RELOAD_PLACE":
      return {...state, profile:action.payload};
    default:
      return state;
  }
};

const PlaceContext = React.createContext(initialState);

export const PlaceProvider = PlaceContext.Provider
export const PlaceConsumer = PlaceContext.Consumer
export default PlaceContext