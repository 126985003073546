import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-reader'
// import { Fab } from '@material-ui/core';
import { Link } from 'react-router-dom';
import homepagedesktop from '../images/homepage_desktop.jpg'
import axios from 'axios'
import HeaderHomepage from './HeaderHomepage';
import { gtbl, getLastLanguageSelected, setLangFromBrowser, gtbl_list } from '../utils/commons';
import ItemSearch from './Details/ItemSearch';
import FooterInfo from './Details/FooterInfo';

let qdiner_logo = "/static/qdiner_logo.png"
let qdiner_logo_black = "/static/qdiner_logo_black.png"
let instagram_logo = "/static/instagram.png"
let facebook_logo = "/static/facebook.png"

let loginurl="/c/dashboard"

function HomePage(props) {
    const [qrresult, setQrresult] = useState(null)
    const [message, setMessage] = useState(null)
    const [facingMode, setFacingMode] = useState("enviroment")
    const [lang, setLang] = useState(null)
    const [searchQr, setSearchQr] = useState(false)
    const [loading, setLoading] = useState(false)
    const [redirect, setRedirect] = useState(false)
    // const [hiddenChange, sethiddenChange] = useState(true)
    const [viewReader, setViewReader] = useState(false)
    const [changedCamera, setChangedCamera] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [searchDownValue, setsearchDownValue] = useState("")
    const [showSearchField, setShowSearchField] = useState(false)
    const [resultSearch, setResultSearch] = useState([])
    const [next, setNext] = useState(null)
    const [count, setCount] = useState(0)
    const [myTimeout, setMyTimeout] = useState(null)

    function showSearch(){
      if(!showSearchField){
        window.scrollTo(0, 0)
      }
      setShowSearchField(!showSearchField)
      setSearchValue("")
    }

    function handleScan(data){
        if (data) {
          setRedirect(true)
          setQrresult(data)
          setMessage(null)
          setLoading(true)
          if(redirect){
            if(data.startsWith("https://qdiner.app/")){
              window.location.replace(data);
            }
          }
        }
      }
      function handleError(err){
        setMessage(err)
      }
      // function stopRedirect(e){
      //   e.preventDefault()
      //   setRedirect(false)
      // }
    function setChangeCamera(){
      if(!changedCamera){
        setFacingMode("user")
      }else{
        setFacingMode("environment")
      }
      setChangedCamera(!changedCamera)
    }
    // function goToResult(slug){
    //   window.location.replace("/"+slug);
    // }

    useEffect(() => {
      let last_lang = getLastLanguageSelected()
      if(!last_lang){
        let res_lang = setLangFromBrowser(navigator.language)
        setLang(res_lang)
      }
    }, [])

    useEffect(() => {
      setLoading(true)
      if (searchValue.length>2){
        clearTimeout(myTimeout);
        setMyTimeout(setTimeout(() => {
            axios.get('/api/v1/place-list/?search='+searchValue).then((res)=>{
              setCount(res.data.count)
              setNext(res.data.next)
              setResultSearch(res.data.results)
              setLoading(false)
            }).catch(error=>{
              setResultSearch(null)
              setLoading(false)
            })
          },1000)
        )
      }
    }, [searchValue])

    function goToSearch(e){
      e.preventDefault()
      console.log("prova")
      window.location.href = "/search?s="+searchDownValue
    }

    function setOpenSearchQr(value){
    setSearchQr(value) 
    setViewReader(false)
    setTimeout(() => {
        setViewReader(value)
      }, 1000);
    }

    return (
        <div style={{margin:"0 auto",paddingBottom:"25px",paddingTop:"50px",height:"100%"}}>
          <div style={{zIndex:10}}>
            <HeaderHomepage isVisible={true} showSearch={showSearch} loginurl={loginurl}></HeaderHomepage>
            <div className={searchQr ? "div-qrcode-open" : "div-qrcode"}>
              {viewReader ? 
              <>
                <div style={{position:"relative",zIndex:999}}>
                    <QrReader
                      delay={300}
                      onError={handleError}
                      onScan={handleScan}
                      facingMode={facingMode}
                      style={{margin:"0 auto"}}
                      showViewFinder={true}
                      />
                      <span hidden={!searchQr} style={{position:"absolute",right:"20px",top:"25px"}} onClick={(e)=>{e.preventDefault();setChangeCamera()}}>
                          <input autoComplete="off"  type="checkbox" id="unchecked" className="cbx hidden" value={!changedCamera}/>
                          <label htmlFor="unchecked" className="lbl" ></label>
                      </span>
                  </div>
                </>
                  :null}
                </div>
          </div>
          <div className="text-center" style={{height:"100%"}}>
              <div className={showSearchField ? "col-lg-6 col-md-8 col-sm-10 co-xs-12 nopadding search-active" : "col-lg-6 col-md-8 col-sm-10 co-xs-12 nopadding search"}>
                <div className="form-group has-search">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input autoComplete="off"  type="text" value={searchValue} className="form-control" placeholder={gtbl("search")} onChange={(e)=>{setSearchValue(e.target.value)}}/>  
                </div>  
              </div>
              {searchValue.length > 2 && loading ? 
                <div className="text-center">
                  <div className="spinner-border text-secondary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                </div>
            :
            searchValue.length > 2 && !loading ?
              <div className="col-lg-6 col-md-8 col-sm-10 co-xs-12" style={{margin:"0 auto"}}>
                  {resultSearch ?
                  resultSearch.length > 0 ? 
                  <div>
                      {resultSearch.map((result,i)=>{
                        return(
                          <Link style={{textDecoration:"none",color:"#1a171c"}}  key={i} to={{
                            pathname: '/'+result.slug,
                                resturant:result,
                                backurl:"/"
                            }}>
                              <ItemSearch
                              odd={ i % 2 ===0 ? "search-odd":"search-pair"}
                              name={result.name}
                              image={result.image}
                              city={result.city}
                              address={result.address}
                              province={result.province}
                              />
                          </Link>
                        )
                      })}
                  </div>
                  : <div>
                      {loading === false && resultSearch.length === 0 ?
                      gtbl("no_result"): null}
                    </div>
                    :
                    <div>
                      {gtbl("no_connection")}
                    </div>}
              </div>
            :
            <>
            <div className="background-image-fullscreen" style={{background: "url("+homepagedesktop+") center center",zIndex:"1",position:"relative"}}>
              <div className="testo bottone" style={{width:"100%", maxWidth:"400px",position:"absolute",right:"0",paddingRight:"30px",height:"90%",backgroundColor:"white",opacity:"0.8"}} >
                <div style={{color:"#4a4e57",padding:"15px",paddingTop:"30px",paddingRight:"0",textAlign:"center",fontFamily:"Roboto",fontSize:"25px"}}>
                  <div style={{paddingBottom:"20px"}}>
                    <img src={qdiner_logo_black} style={{maxWidth:"100%",height:"60px"}} alt="qdiner logo"/>
                  </div>
                  <div className="form-group has-search" style={{textAlign:"center"}}>
                    <span className="fa fa-search form-control-feedback"></span>
                    <form onSubmit={(e)=>{goToSearch(e)}}>
                      <div className="row">
                        <div className="col-8" style={{margin:0}}>
                          <input autoComplete="off" type="text" value={searchDownValue} className="form-control" placeholder={gtbl("search")} onChange={(e)=>{setsearchDownValue(e.target.value)}}/>  
                        </div>
                        <div className="col-3" style={{padding:0}}>
                          <button className="btn btn-warning"><b style={{fontSize:"15px",padding:"15px"}}>{gtbl("search")}</b></button>
                        </div>
                      </div>
                    </form>
                    <div style={{textAlign:"center", fontSize:"13px",paddingTop:"8px"}}>
                      -oppure-
                    </div>
                  </div>  
                  <span className="text-homepage" style={{opacity:"0.8",padding:"2px"}}>
                    {gtbl_list("home_page_list").map((item,i)=>{
                        return(
                          <span key={i}>{item.text}<br/></span>
                        )
                    })}
                  </span>
                  <div style={{marginTop:"8px"}}>
                    <a href={loginurl}>
                    <button className="btn btn-warning"><b style={{fontSize:"20px",padding:"20px"}}>{gtbl("start_now")}</b></button>
                    </a>
                  </div>
                  <div className="row" style={{marginTop:"25px"}}>
                      <div className="offset-2 col-4">
                        <a href="https://www.facebook.com/Qdiner-103488314794544" target="_blank">
                          <img src={facebook_logo} alt="qdiner.app facebook" style={{height:"40px"}}/>
                        </a>
                      </div>
                      <div className="col-4">
                        <a href="https://instagram.com/qdiner.app" target="_blank">
                          <img src={instagram_logo} alt="qdiner.app instagram" style={{height:"40px"}}/>
                        </a>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="d-none d-sm-block" style={{zIndex: 2,position:"relative"}}>
              <FooterInfo></FooterInfo>
            </div>

            <div className="d-block d-sm-none" style={{zIndex: 2,position:"relative",marginTop:"110px"}}>
              <FooterInfo></FooterInfo>
            </div>
          </>}
          </div>
          {/* <Fab className="fab" color="primary" aria-label="add" onClick={()=>{setOpenSearchQr(!searchQr)}}>
          <i className="fas fa-qrcode" style={{fontSize:"25px"}}></i>
          </Fab> */}
        </div>
    );
}

export default HomePage;