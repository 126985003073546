import React, { useState,useEffect } from 'react';
import HeaderQdiner from '../HeaderQdiner';
import Sidebar from './Sidebar';
import { gtbl,compareByPosition, getNameLangByCode, removeCookieBeforeSave } from '../../utils/commons';
import axios from 'axios'
import { NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';


function AllergenDash(props) {
    const [allergens, setallergens] = useState(null)
    const [place, setPlace] = useState(null)
    const [isLogged, setLogged] = useState()
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        if(props.location.profile){
            let token = localStorage.getItem('tk')
            if(token){
                axios.get('/api/v1/config/allergen/',{headers:{token:token}}).then((res)=>{
                    setallergens(res.data)
                    setPlace(props.location.profile)
                    setLogged(true)
                })
            }else{
                window.location.href="/c/dashboard/"
            }
        }else{
            window.location.href="/c/dashboard/"
        }
    }, [])

    function addAllergen(){
        setSaving(true)
        removeCookieBeforeSave()
        let data = {}
        data["list"]=[]
        data["lang"]="nolang"
        data["status"]="new"
        data["placeid"]=place.id
        let token = localStorage.getItem('tk')
        axios.put('/api/v1/config/allergen/', data, {headers:{token:token}}).then((res)=>{
            let list = allergens
            list.push(res.data)
            setallergens([...list])
            setSaving(false)   
        }).catch(error=>{
            NotificationManager.error(gtbl("error_message"));
            setSaving(false)   
        })
    }
    
    function deleteAllergen(id,i){
        let r = window.confirm(gtbl("confirm_delete") +"?")
        if(r){
            removeCookieBeforeSave()
            let deleting_all = allergens.find(x=>x.id = id)
            let data = deleting_all
            data["status"]="delete"
            data["placeid"]=place.id
            let token = localStorage.getItem('tk')
            
            //TODO: ATTENZIONE DA CORREGGERE L'ERRORE DEL SELECTED MENU SUL CAMPO data
            //TODO: sbagliato, ma cambiare poi intanto deve funzionare
            axios.post('/api/v1/config/allergen/', data, {headers:{token:token}}).then((res)=>{
                allergens.splice(i,1)      
                setallergens([...allergens])
                NotificationManager.success(gtbl("success_message"));    
            }).catch(error=>{
                NotificationManager.error(gtbl("error_message"));
            })
        }
    }

    function upPosition(i){
        let positionselected = allergens[i].position
        let positionselected2 = allergens[i-1].position
        var list = allergens
        list[i].position =  positionselected2
        list[i-1].position = positionselected
        setallergens([...list])
    }
    function downPosition(i){
        let positionselected = allergens[i].position
        let positionselected2 = allergens[i+1].position
        var list = allergens
        list[i].position =  positionselected2
        list[i+1].position = positionselected
        setallergens([...list])
    }

    return (
        <div>
            <HeaderQdiner></HeaderQdiner>
            {isLogged ?
            <>
                <Sidebar></Sidebar>
                <div className="content row">
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <div style={{maxWidth:"600px",margin:"0 auto"}}>
                                {allergens.sort(compareByPosition).map((allergen,i)=>{
                                    return(
                                        <div className={ i % 2 === 0 ? "background-grey-card background-grey-card-odd": "background-grey-card background-grey-card-pair"}>
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                                    <span className="">{allergen.lang ? getNameLangByCode(allergen.lang.toLowerCase()):<small>no name</small>}</span>
                                                    <div className="" style={{maxWidth:"70px"}} hidden={true}>
                                                        {i !== allergens.length-1 ?
                                                        <span onClick={()=>{downPosition(i)}} style={{paddingRight:"5px" , marginTop:"10px"}}>
                                                            <i className="fas fa-arrow-down"></i>
                                                        </span>
                                                        :null}
                                                        {i !== 0 ? 
                                                        <span onClick={()=>{upPosition(i)}} style={{ marginTop:"10px"}}>
                                                            <i className="fas fa-arrow-up"></i>
                                                        </span>
                                                        :null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <Link style={{textDecoration:"none"}} to={{
                                                            pathname: '/c/dashboard/allergen/'+allergen.id+'/',
                                                                place:place,
                                                                allergen:allergen,
                                                                profilename:props.location.profile.name,
                                                                profileimage:props.location.profile.image
                                                            }}>
                                                            <button className="btn btn-warning" style={{marginBottom:"15px",width:"100%"}}>
                                                            <i className="fas fa-pen"></i> {gtbl("modify")}</button>
                                                        </Link>
                                                    </div>
                                                    <div className="col-12">
                                                        <button className="btn btn-danger" style={{marginBottom:"15px",width:"100%"}} onClick={(e)=>{deleteAllergen(allergen.id,i)}}>
                                                            <i className="fas fa-trash-alt"></i> {gtbl("delete")}
                                                        </button>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div>
                                    <button className="btn btn-info" onClick={()=>addAllergen()} style={{width:"100%"}}><i className="fas fa-plus"></i> { saving ? gtbl("creating") : gtbl("new")}</button>
                                </div>
                        </div>
                        
                    </div>
                    {/* <div className="col-lg-4 d-none d-lg-block" style={{textAlign:"center",position:"fixed", right:0}}>
                        <PlaceProvider value={{state, dispatch}}>
                        <div className="marvel-device nexus5">
                            <div className="top-bar"></div>
                            <div className="sleep"></div>
                            <div className="volume"></div>
                            <div className="camera"></div>
                            <div className="screen scrolller" style={{overflowY:"scroll"}}>
                            <div>
                                <Header isDashboard={true} backurl={"#"} backgroundcolor={""} color={""} image={props.location.profile.image} name={props.location.profile.name}></Header>
                            </div>
                            <div style={{padding:"5px"}}>
                                {allergens.map((allergen,i)=>{
                                    return(
                                        <div className={ i % 2 === 0 ? "background-grey-card background-grey-card-odd": "background-grey-card background-grey-card-pair"}>
                                            <div style={{paddingLeft:"5px",paddingRight:"5px"}} className="col-12">
                                                <span className="">{allergen.lang}</span>
                                            </div>
                                        </div>
                                    )}
                                )}
                            </div>
                            </div>
                        </div>
                        </PlaceProvider>
                    </div> */}
                </div>
            </>
        : 
        <div className="text-center">
            <div className="spinner-border text-secondary spinner-bootstrap" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>}    
    </div>
    );
}

export default AllergenDash;