import React, { useState,useEffect } from 'react';
import HeaderQdiner from '../HeaderQdiner';
import Sidebar from './Sidebar';
import { gtbl,compareByPosition, removeCookieBeforeSave } from '../../utils/commons';
import axios from 'axios'
import { NotificationManager } from 'react-notifications';
import Header from '../Header';
import languages from '../../utils/languages.json'


function DetailAllergenDash(props) {
    const [listAllergen, setListAllergen] = useState([])
    const [allergenId, setAllergenId] = useState(null)
    const [placeid, setPlaceid] = useState(null)
    const [profile, setProfile] = useState(null)
    const [lang, setLang] = useState(null)
    const [title, setTitle] = useState(null)
    const [name, setName] = useState(null)
    const [description, setDescription] = useState(null)
    const [isLogged, setLogged] = useState()
    const [saving, setSaving] = useState(false)

    // useEffect(() => {
    //     if(props.location.profile){
    //         let token = localStorage.getItem('tk')
    //         if(token){
    //             axios.get('/api/v1/config/allergen/',{headers:{token:token}}).then((res)=>{
    //                 setProfile(props.location.profile)
    //                 setPlaceid(props.location.profile.id)
    //                 setAllergenId(res.data[0].id)
    //                 setLang(res.data[0].lang)
    //                 setListAllergen(res.data[0].listAllergens)
    //                 setLogged(true)
    //             })
    //         }else{
    //             window.location.href="/c/dashboard/"
    //         }
    //     }else{
    //         window.location.href="/c/dashboard/"
    //     }
    // }, [])

    useEffect(() => {
        if(props.location.allergen && props.location.place){
            setProfile(props.location.place)
            setPlaceid(props.location.place.id)
            setAllergenId(props.location.allergen.id)
            setListAllergen(props.location.allergen.listAllergens)
            setLang(props.location.allergen.lang)
            setTitle(props.location.allergen.title)
            setLogged(true)
        }else{
            window.location.href="/c/dashboard/"
        }
    }, [])

    function saveList(e){
        e.preventDefault();
        setSaving(true)
        let data = {}
        removeCookieBeforeSave()
        data["list"]=listAllergen
        data["id"]=allergenId
        data["lang"]=lang
        data["title"]=title
        data["placeid"]=placeid
        let token = localStorage.getItem('tk')
        //TODO: ATTENZIONE DA CORREGGERE L'ERRORE DEL SELECTED MENU SUL CAMPO data
        axios.put('/api/v1/config/allergen/', data, {headers:{token:token}}).then((res)=>{
            NotificationManager.success(gtbl("success_message")); 
            setSaving(false)   
        }).catch(error=>{
            NotificationManager.error(gtbl("error_message"));
            setSaving(false)   
        })
    }

    function addNew(e){
        e.preventDefault()
        var lista = listAllergen
        lista.push({name:name,description:description,position:listAllergen.length})
        setListAllergen(lista)
        setName("")
        setDescription("")
    }
    function deleteItem(position){
        var list = listAllergen
        list.splice(position,1)
        setListAllergen([...list])
    }
    function upPosition(i){
        let positionselected = listAllergen[i].position
        let positionselected2 = listAllergen[i-1].position
        var list = listAllergen
        list[i].position =  positionselected2
        list[i-1].position = positionselected
        setListAllergen([...list])
    }
    function downPosition(i){
        let positionselected = listAllergen[i].position
        let positionselected2 = listAllergen[i+1].position
        var list = listAllergen
        list[i].position =  positionselected2
        list[i+1].position = positionselected
        setListAllergen([...list])
    }

    return (
        <div>
            <HeaderQdiner></HeaderQdiner>
            
            {isLogged ?
            <>
            <Sidebar></Sidebar>
            
            <div className="content row">
                <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                <div style={{maxWidth:"600px",margin:"0 auto"}}>
                    <div style={{position:"fixed",width:"100%",maxWidth:"600px",backgroundColor:"white",zIndex:"101",top:"50px",paddingTop:"15px"}}>
                        <button disabled={saving} className="btn btn-info" onClick={(e)=>{saveList(e)}}>
                            <i className="fa fa-save"></i> { !saving? gtbl("save"):gtbl("saving")}
                        </button> 
                        <hr/>
                    </div>
                    <div style={{marginTop:"50px"}}>
                        <div className="form-group">
                            <label>{gtbl("language")}</label>
                            <select name="lang" value={lang} onChange={(e)=>{setLang(e.target.value)}} className="form-control">
                                {languages.map((country,i)=>{
                                    return(
                                    <option key={i} value={country.code}>{country.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="form-group input-group-sm">
                            <label>{gtbl("name")}</label>
                            <input autoComplete="off"  type="text" name="title" value={title} onChange={(e)=>setTitle(e.target.value)} className="form-control" placeholder=""/>
                        </div>
                        {listAllergen.sort(compareByPosition).map((allergen,i)=>{
                            return(
                                <div key={i} className={i%2===0 ? "item-list" : "item-list-odd"}>
                                    <div className="row">
                                        <div className="col-2" style={{maxWidth:"70px"}}>
                                            {i !== listAllergen.length-1 ?
                                            <span onClick={()=>{downPosition(i)}} style={{paddingRight:"5px" , marginTop:"10px"}}>
                                                <i className="fas fa-arrow-down"></i>
                                            </span>
                                            :null}
                                            {i !== 0 ? 
                                            <span onClick={()=>{upPosition(i)}} style={{ marginTop:"10px"}}>
                                                <i className="fas fa-arrow-up"></i>
                                            </span>
                                            :null}
                                        </div>
                                        <div className="col-9" style={{fontSize:"15px"}}>
                                            
                                            <b>{allergen.name}</b> - {allergen.description}
                                        </div>
                                        <div className="col-1">
                                            <button className="btn btn-danger" onClick={()=>{deleteItem(i)}}>X</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <div style={{padding:"10px"}}>
                            <div className="item-list"><h5>{gtbl("add_new")}</h5></div>
                            <form onSubmit={(e)=>{addNew(e)}}>
                                <div className="form-group input-group-sm">
                                    <label>{gtbl("name")}</label>
                                    <input autoComplete="off"  type="text" name="name" value={name} onChange={(e)=>setName(e.target.value)} className="form-control" placeholder=""/>
                                </div>
                                <div className="form-group input-group-sm">
                                    <label>{gtbl("description")}</label>
                                    <input autoComplete="off"  required type="text" name="description" maxLength={100} value={description} onChange={(e)=>setDescription(e.target.value)} className="form-control" placeholder=""/>
                                </div>
                                <button className="btn btn-primary"><i className="fas fa-plus"></i> {gtbl("add")}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 d-none d-lg-block" style={{textAlign:"center",position:"fixed", right:0}}>
                <div className="marvel-device nexus5">
                    <div className="top-bar"></div>
                    <div className="sleep"></div>
                    <div className="volume"></div>
                    <div className="camera"></div>
                    <div className="screen scrolller" style={{overflowY:"scroll"}}>
                        <Header isDashboard={true} backurl={"/"+profile.slug} backgroundcolor={""} color={""} image={profile.image} name={profile.name}></Header>
                        <div style={{paddingLeft:"5px",paddingRight:"5px"}}>
                        <div className="col-12" style={{textAlign:"left"}}>
                            {title}
                        </div>
                        {listAllergen.map((allergen,i)=>{
                            return(
                                <div key={i} className={i%2===0 ? "item-list" : "item-list-odd"}>
                                    <b>{allergen.name}</b> - {allergen.description}
                                </div>
                            )
                        })}
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        </>:
        <div className="text-center">
            <div className="spinner-border text-secondary spinner-bootstrap" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>}
    </div>
    );
}

export default DetailAllergenDash;