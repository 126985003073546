import React,{useEffect,useState} from 'react';
import axios from 'axios'
import { gtbl,formatDate, expiringAccount } from '../../utils/commons';
import HeaderQdiner from '../HeaderQdiner';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Sidebar from './Sidebar';
import CheckoutForm from '../Payment/CheckoutForm'
import {NotificationManager} from 'react-notifications';

String.prototype.format = function () {
    var i = 0, args = arguments;
    return this.replace(/{}/g, function () {
        return typeof args[i] != 'undefined' ? args[i++] : '';
    });
};

const stripePromise = loadStripe("pk_live_51H0lO7FI9OhfmoJZ6r97v2eoZGLWoYjtldFOzoY2uYRfoJAX18PhQ3QfwMLW1tb0uFY58XIesJqXk1AzfBzTCbJO00TKauDoqO");
let prezzo_pieno = 14.9
let prezzo_mese_anno = 9.9
let prezzo_mese_sei_mesi = 11.9
let prezzo_sei_mesi = 71.4
let prezzo_annuo = 118.8
let iva = 22.0

let token = localStorage.getItem('tk')

function AccountDash(props) {
    const [originalUser, setOriginalUser] = useState()
    const [user, setUser] = useState(null)
    const [paying, setPaying] = useState(null)
    const [paied, setPaied] = useState(false)
    const [isLogged, setIsLogged] = useState(false) 
    const [fee, setFee] = useState(prezzo_pieno)
    const [period, setPeriod] = useState("month")
    const [paymentData, setPaymentData] = useState(null)

    function changePeriod(type){
        setPeriod(type)
        if(type==="month"){
            setFee(prezzo_pieno)
        }if(type==="6month"){
            setFee(prezzo_sei_mesi)
        }else{
            setFee(prezzo_annuo)
        }
    }
    function setMessagePaing(message){
        NotificationManager.warning(message);
    }
    function pay(payment_id,status,type_of_payment){
        if(originalUser.name){
            
            if(status==="succeeded"){
                user.renew="true"
                let paid=(Number(fee)+Number((fee * iva)/100)).toFixed(2)
                let data={user:user,type:"set_payment",kind:period,paid:paid,type_of_payment:type_of_payment,payment_id:payment_id}
                axios.put('/api/v1/user/account/', data,{headers:{token:token}}).then(res=>{
                    NotificationManager.success(gtbl("success_message_payment"));
                    setUser(res.data.user)
                    setOriginalUser(res.data.user)
                    setPaied(true)
                }).catch(error=>{
                    NotificationManager.success(gtbl("samething_wrong"));  
                })
            }
        }else{
            NotificationManager.warning(gtbl("complete_profile"));
        }
    }

    function aggiornaProfilo(e){
        e.preventDefault()
        let data={user:user,type:"update_data"}
        axios.put('/api/v1/user/account/', data,{headers:{token:token}}).then(res=>{
            NotificationManager.success(gtbl("success_message"));
            setUser(res.data.user)
            setOriginalUser(res.data.user)
        })
    }
    function updateUserData(e){
        setUser({...user, [e.target.name]:e.target.value})
    }

    useEffect(() => {
        axios.get('/api/v1/user/account/', {headers:{token:token}}).then((res)=>{
            console.log(res)
            if(!res.data.error){
                setIsLogged(true)
                setUser(res.data.data)
                setPaymentData(res.data.payment)
                setOriginalUser(res.data.data)
            }else{
                window.location.href="/c/login"
            }
        }).catch((error)=>{
            window.location.href="/c/login"
        })
    }, [])

    return (
        <div>
        <HeaderQdiner></HeaderQdiner>
        {isLogged && user?
        <>
            <Sidebar></Sidebar>
            <div className="container row" style={{margin:"0 auto",paddingLeft:0,paddingRight:0}}>
            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12" style={{paddingBottom:"30px"}}>
                    <div>
                        <small>({gtbl("active_from")} {formatDate(new Date(user.timestamp))})</small>
                    </div>
                    <div>
                    {expiringAccount(user.validity_to,new Date(),0) ? gtbl("validity_until") : gtbl("expired_account")} : <b style={!expiringAccount(user.validity_to,new Date()) ? {color:"red"}:null}>{formatDate(new Date(user.validity_to))}</b>
                    </div>
                    <div style={{paddingTop:"15px",paddingBottom:"10px"}}>
                        <div style={{marginBottom:"15px"}}>
                            <input autoComplete="off"  type="radio" name="" checked={period==="month" ? true : false} onChange={()=>changePeriod("month")}/> 
                            <span dangerouslySetInnerHTML={{__html:gtbl("pay_month").format(prezzo_pieno.toFixed(2))}} />

                        </div>
                        <div style={{marginBottom:"15px"}}>
                            <input autoComplete="off"  type="radio" name="" checked={period==="6month" ? true : false} onChange={()=>changePeriod("6month")}/> 
                            <span dangerouslySetInnerHTML={{__html: gtbl("pay_6month").format(prezzo_mese_sei_mesi.toFixed(2))}} />
                            <br/> <small style={{marginLeft:"15px"}}>{gtbl("total_6_month").format(prezzo_sei_mesi.toFixed(2))}</small>
                        </div>
                        <div style={{marginBottom:"15px"}}>
                            <input autoComplete="off"  type="radio" name="" checked={period==="year" ? true : false} onChange={()=>changePeriod("year")}/> 
                            <span dangerouslySetInnerHTML={{__html: gtbl("pay_year").format(prezzo_mese_anno.toFixed(2))}} />
                            <br/> <small style={{marginLeft:"15px"}}>{gtbl("total_1_year").format(prezzo_annuo.toFixed(2))}</small>  
                        </div>
                    </div>
                    <div className="col-12" style={{borderTop:"1px solid lightgrey",borderBottom:"1px solid lightgrey"}}>
                        <div className="row">
                            <div className="col-5"></div>
                            <div className="col-7" style={{textAlign:"right"}}>{fee.toFixed(2)}€</div>
                        </div>
                        <div className="row" style={{fontSize:"13px"}}>
                            <div className="col-5">{gtbl("vat")} ({iva})%:</div>
                            <div className="col-7" style={{textAlign:"right"}}>+{((fee * iva)/100).toFixed(2)}€</div>
                        </div>
                        <div className="row" style={{fontSize:"20px",fontWeight:"bold"}}>
                            <div className="col-5">{gtbl("total")}:</div>
                            <div className="col-7" style={{textAlign:"right"}}>{(Number(fee)+Number((fee * iva)/100)).toFixed(2)}€</div>
                        </div>
                    </div>
                    {!paied ?
                    <Elements stripe={stripePromise}>  
                        <div style={{marginTop:"15px"}}>
                            <CheckoutForm prezzo={(Number(fee)+Number((fee * iva)/100)).toFixed(2)} setMessagePaing={setMessagePaing} 
                                email={user.email}
                                nomecognome={user.name+" "+user.lastname} pay={pay} isEnabled={originalUser && originalUser.name && originalUser.lastname ? true:false}></CheckoutForm>
                            {/* <button style={{width:"100%",maxWidth:"350px"}} onClick={(e)=>{pay()}} className="btn btn-warning">{gtbl("renew")}</button> */}
                        </div>
                        <div style={{textAlign:"center"}}>
                            <small>({gtbl("renew_to")} {period==="month" ? formatDate(new Date(user.validity_to),1):formatDate(new Date(user.validity_to),12)})</small>
                        </div>
                    </Elements>:
                    <div style={{marginTop:"15px", textAlign:"center"}}>
                        {gtbl("paid_complete_successful")}    
                    </div>
                    }
                </div>
                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                    <div>
                    <form onSubmit={(e)=>{aggiornaProfilo(e)}}>
                        <div className="form-group input-group-sm">
                            <label >{gtbl("email_address")}</label>
                            <input required disabled={true} type="email" name="email" value={user.email} onChange={(e)=>{updateUserData(e)}} className="form-control" placeholder="Email"/>
                        </div>
                        <div className="form-group input-group-sm">
                            <label >{gtbl("business_name")}</label>
                            <input required name="business_name" value={user.business_name} onChange={(e)=>{updateUserData(e)}} className="form-control" placeholder="Business name"/>
                        </div>
                        <div className="row">
                            <div className="form-group input-group-sm col-6">
                                <label>{gtbl("name")}</label>
                                <input required type="" name="name" value={user.name} onChange={(e)=>{updateUserData(e)}} className="form-control" placeholder="Name"/>
                            </div>
                            <div className="form-group input-group-sm col-6">
                                <label>{gtbl("lastname")}</label>
                                <input  required type="" name="lastname" value={user.lastname} onChange={(e)=>{updateUserData(e)}} className="form-control" placeholder="Lastname"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group input-group-sm col-6">
                                <label>{gtbl("country")}</label>
                                <input required type="" name="country" value={user.country} onChange={(e)=>{updateUserData(e)}} className="form-control" placeholder="Address"/>
                            </div>
                            <div className="form-group input-group-sm col-6">
                                <label>{gtbl("city")}</label>
                                <input required type="" name="city" value={user.city} onChange={(e)=>{updateUserData(e)}} className="form-control" placeholder="City"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group input-group-sm col-6">
                                <label>{gtbl("province")}</label>
                                <input required type="" name="province" value={user.province} onChange={(e)=>{updateUserData(e)}} className="form-control" placeholder="Province"/>
                            </div>
                            <div className="form-group input-group-sm col-6">
                                <label>{gtbl("zipcode")}</label>
                                <input required type="" name="zipcode" value={user.zipcode} onChange={(e)=>{updateUserData(e)}} className="form-control" placeholder="Zipcode"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group input-group-sm col-12">
                                <label>{gtbl("address")}</label>
                                <input  required type="" name="address" value={user.address} onChange={(e)=>{updateUserData(e)}} className="form-control" placeholder="Address"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group input-group-sm col-12">
                                <label>{gtbl("vat_number")}</label>
                                <input required type="" name="vat_number" value={user.vat_number} onChange={(e)=>{updateUserData(e)}} className="form-control" placeholder="vat number"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group input-group-sm col-12">
                                <label>{gtbl("sdi_code")}</label>
                                <input type="" name="sdi_code" value={user.sdi_code} onChange={(e)=>{updateUserData(e)}} className="form-control" placeholder="SDI code"/>
                            </div>
                        </div>
                        <div style={{padding:"15px 0"}} className="d-none d-sm-block" >
                            <button className="btn btn-info">{gtbl("update_profile")}</button> 
                            <br/>
                            {originalUser && originalUser.name && originalUser.lastname ? null : <small>({gtbl("profile_not_completed")})</small> }
                        </div>
                        <div style={{padding:"15px 0"}} className="d-block d-sm-none" >
                            <hr/>
                            <button className="btn btn-info" style={{width:"100%"}}>{gtbl("update_profile")}</button> 
                        </div>
                    </form>
                    </div>
                    <div>
                    <div><h4>{gtbl("previous_payments")}</h4></div>
                    <table className="table">
                        <thead>
                            <tr>
                            <th scope="col">{gtbl("name")}</th>
                            <th scope="col">{gtbl("paid")}</th>
                            <th scope="col">{gtbl("date")}</th>
                            <th scope="col" className="d-none d-sm-block">Id</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentData && paymentData.map((payment,i)=>{
                                return(
                                    <tr>
                                        <td><small>{payment.name}</small></td>
                                        <td><small>{payment.paid}€</small></td>
                                        <td><small>{payment.timestamp.split("T")[0]}</small></td>
                                        <td className="d-none d-sm-block"><small>{payment.payment_id}</small></td>
                                    </tr>
                                )
                            })}
                            
                        </tbody>
                        </table>
                    </div>
                </div>
                
            </div>


        </>
        :<div className="text-center">
            <div className="spinner-border text-secondary spinner-bootstrap" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>}
            
        </div>
    );
}

export default AccountDash;