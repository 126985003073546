import React, { useState,useEffect } from 'react';
import axios from 'axios'
import HeaderQdiner from '../HeaderQdiner';
import { gtbl } from '../../utils/commons';

function ChangePassword(props) {
    const [rePassword, setRePassword] = useState("")
    const [password, setPassword] = useState("")
    const [message, setMessage] = useState("")

    function changePassword(e){
        e.preventDefault()
        if(password!==rePassword){
            setMessage("Le due password devono essere uguali")
        }else{
            setMessage("Attendi completamento operazione...")
            let data={
                recover_id:props.match.params.recover_id,
                type:"change_password",
                new_password:password
            }
            axios.put("/api/v1/user/",data).then((res)=>{
                if(res.data.msg){
                    setMessage(res.data.msg)
                    setTimeout(() => {
                      window.location.href = "/c/dashboard/"
                    }, 5000);
                }
            }).catch(()=>{
                setMessage("Qualcosa è andato storto, contattami ad info@qdiner.app se non riesci a proseguire")
            })
        }  
    }
  return (
        <div className="container login-container">
          <HeaderQdiner></HeaderQdiner>
          <div className="row padding-header h-100">
            <div className="col-md-6 login-form-2" style={{margin:"0 auto"}}>
                <form onSubmit={(e)=>{changePassword(e)}}>
                    <div className="form-group">
                        <input required type="password" className="form-control" placeholder="New Password *" value={password} onChange={(e)=>{setPassword(e.target.value)}} />
                    </div>
                    <div className="form-group">
                        <input required type="password" className="form-control" placeholder="Repeat Password *" value={rePassword} onChange={(e)=>{setRePassword(e.target.value)}} />
                    </div>
                    <div className="form-group">
                        <input type="submit" className="btnSubmit btn btn-primary" style={{width:"100%"}} value={gtbl("save_password")} />
                    </div>
                </form>
              <div style={{textAlign:"center",color:"white"}}>
                {message}
              </div>
            </div>
          </div>
        </div>
      
  );
}

export default ChangePassword;
