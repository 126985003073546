import React, { useState, useEffect, useContext, useReducer } from 'react';
import {NotificationManager} from 'react-notifications';
import axios from 'axios'
import PlaceContext, { PlaceProvider, PlaceReducer, initialState } from '../Context/PlaceContext';
import HeaderQdiner from '../HeaderQdiner';
import Sidebar from './Sidebar';
import {gtbl,compareByPosition, getNameLangByCode, removeCookieBeforeSave} from '../../utils/commons'
import { Link } from 'react-router-dom';
import Header from '../Header';

let token = localStorage.getItem('tk')

function MenuDash(props) {
    const context = useContext(PlaceContext)
    const [state, dispatch] = useReducer(PlaceReducer, initialState)
    const [isLogged, setLogged] = useState(false)
    const [listMenu, setListMenu] = useState(null)
    const [saving, setSaving] = useState(false)
    const [placeid, setPlaceid] = useState(null)
    
    useEffect(() => {
        if(props.location.profile){
            setPlaceid(props.location.profile.id)
            let profilemenu = props.location.profile.menus
            profilemenu.sort(compareByPosition).forEach((element,i) => {
                if(element.position !== i){
                    element.position=i-1
                    element["placeid"]=props.location.profile.id
                    axios.put('/api/v1/config/menu/', element, {headers:{token:token}}).then((res)=>{})
                }
            });
            setListMenu(profilemenu)
            setLogged(true)
        }else{
            window.location.href="/c/dashboard/"
        }
    }, [])

    function upPosition(i){
        let positionselected = listMenu[i].position
        let positionselected2 = listMenu[i-1].position
        var list = listMenu
        list[i].position =  positionselected2
        list[i-1].position = positionselected
        setListMenu([...list])
        list[i]["placeid"] = placeid
        list[i-1]["placeid"] = placeid
        axios.all([axios.put('/api/v1/config/menu/', list[i], {headers:{token:token}}),
                    axios.put('/api/v1/config/menu/', list[i-1], {headers:{token:token}})]).then((res)=>{})
    }
    function downPosition(i){
        let positionselected = listMenu[i].position
        let positionselected2 = listMenu[i+1].position
        var list = listMenu
        list[i].position =  positionselected2
        list[i+1].position = positionselected
        setListMenu([...list])
        list[i]["placeid"] = placeid
        list[i+1]["placeid"] = placeid
        
        axios.all([axios.put('/api/v1/config/menu/', list[i], {headers:{token:token}}),
                axios.put('/api/v1/config/menu/', list[i+1], {headers:{token:token}})]).then((res)=>{})
    }

    function addMenu(){
        //lo manda direttamente al nuovo menu
        removeCookieBeforeSave()
        let randomID = (Math.floor(Math.random()*10000));
        let placeholdermenu={
            id: randomID,
            flows:[]
            ,status:"new"
            ,menu_days: [{"day": "0","isPresent": true}, {"day": "1","isPresent": true}, 
            {"day": "2","isPresent": true}, {"day": "3","isPresent": true}, {"day": "4","isPresent": true}, 
            {"day": "5","isPresent": true}, {"day": "6","isPresent": true}],
            lang: props.location.profile.default_lang,
            title: gtbl("chang_id"),
            subtitle:"",
            position: listMenu.length,
            isTastingMenu: false,
            price_tasting_menu: 0,
            currency:"",
            sale_price_perc: 0,
            validity_from: null,
            validity_to: null,
            font_type: null,
            isActive: false,
            notes: "",
            price_cover: 0
        }
        ////////////
        setSaving(true)
        let data = placeholdermenu
        data["placeid"] = placeid
        axios.put('/api/v1/config/menu/', data, {headers:{token:token}}).then((res)=>{
            listMenu.push(res.data)
            setListMenu(listMenu)
            setSaving(false)   
        }).catch(error=>{
            NotificationManager.error(gtbl("error_message"));
            setSaving(false)   
        })
    }
    function deleteMenu(id,title,i){
        let r = window.confirm(gtbl("confirm_delete") + title +"?")
        if(r){
            removeCookieBeforeSave()
            let deleting_menu = listMenu.find(x=>x.id = id)
            let data = deleting_menu
            data["placeid"] = placeid
            //TODO: ATTENZIONE DA CORREGGERE L'ERRORE DEL SELECTED MENU SUL CAMPO data
            //TODO: sbagliato, ma cambiare poi intanto deve funzionare
            axios.post('/api/v1/config/menu/', data, {headers:{token:token}}).then((res)=>{
                listMenu.splice(i,1)      
                setListMenu(listMenu)
                NotificationManager.success(gtbl("success_message"));    
            }).catch(error=>{
                NotificationManager.error(gtbl("error_message"));
            })
        }
    }
    return (
        <div>
            <HeaderQdiner></HeaderQdiner>
            {isLogged ?
            <>
                <Sidebar></Sidebar>
                <div className="content row">
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <div style={{maxWidth:"600px",margin:"0 auto"}}>
                                {listMenu.sort(compareByPosition).map((menu,i)=>{
                                    return(
                                        <div key={i} className={ i % 2 === 0 ? "background-grey-card background-grey-card-odd": "background-grey-card background-grey-card-pair"}>
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                                    <div className=""> 
                                                    <span style={{paddingRight:"15px"}}>
                                                        {i !== listMenu.length-1 ?
                                                        <span onClick={()=>{downPosition(i)}} style={{paddingRight:"5px" , marginTop:"10px"}}>
                                                            <i className="fas fa-arrow-down"></i>
                                                        </span>
                                                        :null}
                                                        {i !== 0 ? 
                                                        <span onClick={()=>{upPosition(i)}} style={{ marginTop:"10px"}}>
                                                            <i className="fas fa-arrow-up"></i>
                                                        </span>
                                                        :null}
                                                    </span>
                                                    {menu.title}</div>
                                                    <hr/>
                                                    <div style={{fontSize:"13px"}}>
                                                        <span style={{fontWeight:"100"}}>{gtbl("language")}:</span> {getNameLangByCode(menu.lang)}</div>
                                                    <div style={{fontSize:"13px"}}><span style={{fontWeight:"100"}}>{gtbl("status")}:</span> {menu.isActive ? gtbl("publish") : gtbl("not_publish")}</div>
                                                    <br/>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <Link style={{textDecoration:"none"}} to={{
                                                            pathname: '/c/dashboard/menus/'+menu.id+'/',
                                                                placeid:placeid,
                                                                menu:menu,
                                                                profile:props.location.profile
                                                            }}>
                                                            <button className="btn btn-warning" style={{marginBottom:"15px",width:"100%"}}>
                                                                <i className="fas fa-pen"></i> {gtbl("modify")}
                                                            </button>
                                                        </Link>
                                                    </div>
                                                    <div className="col-12">
                                                        <button className="btn btn-danger" style={{marginBottom:"15px",width:"100%"}} onClick={(e)=>{deleteMenu(menu.id,menu.title,i)}}>
                                                            <i className="fas fa-trash-alt"></i> {gtbl("delete")}
                                                        </button>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div>
                                    <button className="btn btn-info" onClick={()=>addMenu()} style={{width:"100%"}}><i className="fas fa-plus"></i> { saving ? gtbl("creating") : gtbl("new")}</button>
                                </div>
                        </div>
                        
                    </div>
                    <div className="col-lg-4 d-none d-lg-block" style={{textAlign:"center",position:"fixed", right:0}}>
                        <PlaceProvider value={{state, dispatch}}>
                        <div className="marvel-device nexus5">
                            <div className="top-bar"></div>
                            <div className="sleep"></div>
                            <div className="volume"></div>
                            <div className="camera"></div>
                            <div className="screen scrolller" style={{overflowY:"scroll"}}>
                            
                            <Header isDashboard={true} backurl={"#"} backgroundcolor={""} color={""} image={props.location.profile.image} name={props.location.profile.name}></Header>
                            <div style={{padding:"5px"}}>
                                {listMenu.map((menu,i)=>{
                                    return(
                                        <div key={i} className={ i % 2 === 0 ? "background-grey-card background-grey-card-odd": "background-grey-card background-grey-card-pair"}>
                                            <div style={{paddingLeft:"5px",paddingRight:"5px"}} className="col-12">
                                                <div className="">{menu.title}</div>
                                                <div style={{fontSize:"13px",fontWeight:"100"}}>{menu.subtitle}</div>
                                            </div>
                                        </div>
                                    )}
                                )}
                            </div>
                            </div>
                        </div>
                        </PlaceProvider>
                    </div>
                </div>
            </>
        : 
        <div className="text-center">
            <div className="spinner-border text-secondary spinner-bootstrap" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>}    
    </div>
    );
}

export default MenuDash;