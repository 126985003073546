import React from 'react';
import { gtbl } from '../../utils/commons';

function FooterInfo(props) {
    return (
        <div style={{textAlign:"center"}}>
            <div className="container-fluid homepage-bar-bottom" >
              <div className="row homepage-info-back" style={{zIndex:10}}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="container  d-none d-sm-block" style={{maxWidth:"800px",margin:"0 auto"}}>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 homepage-info-dettail">
                        <div className="row">
                          <div className="col-lg-4  col-md-4 col-sm-6 col-xs-12 offset-lg-2 offset-md-2">
                            <div className="main-price">9<span className="no-main-price">,90</span></div>
                            <div>€/{gtbl("month")} <small>(+{gtbl("vat")})</small></div>
                          </div>
                          <div className="col-lg-4  col-md-4 col-sm-6 col-xs-12">
                            <div style={{marginTop:"15px",textAlign:"left"}}>{gtbl("no_activation_fee")}</div>
                          </div>
                        </div>
                        <div style={{marginTop:"10px"}}>
                          <b>{gtbl("try_for_free")}</b>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 homepage-info-dettail-center">
                      <div className="row">
                          <div className="col-lg-4  col-md-4 col-sm-6 col-xs-12 offset-lg-2 offset-md-2">
                            <div className="main-price" style={{fontSize:"40px"}}><i className="fas fa-mobile-alt" aria-hidden="false"></i></div>
                          </div>
                          <div className="col-lg-4  col-md-4 col-sm-6 col-xs-12">
                            <div style={{marginTop:"15px",textAlign:"left"}}>{gtbl("simple_and_intuitive")}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div className="container d-block d-sm-none" style={{color:"white",textAlign:"center"}}>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12">
                            <div className="main-price">9<span className="no-main-price">,90</span></div>
                            <div>€/{gtbl("month")} <small>(+{gtbl("vat")})</small></div>
                          </div>
                          <div className="col-lg-4  col-md-4 col-sm-6 col-xs-12">
                            <div style={{marginTop:"15px"}}>{gtbl("no_activation_fee")}<br/></div>
                          </div>
                        </div>
                        <div style={{marginTop:"10px"}}>
                          <b>{gtbl("try_for_free")}</b>
                        </div>
                      </div>
                      <div className="col-12" style={{borderTop:"1px solid whitesmoke",marginTop:"15px"}}>
                        <div className="row">
                          <div className="col-12">
                            <div className="main-price-mobile" style={{fontSize:"40px"}}><i className="fas fa-mobile-alt" aria-hidden="false"></i></div>
                          </div>
                          <div className="col-lg-4  col-md-4 col-sm-6 col-xs-12">
                            <div style={{marginTop:"15px"}}>{gtbl("simple_and_intuitive")}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <a href="/policycookie" target="_blank" style={{color:"black",fontSize:"13px",fontFamily:"Roboto"}}>
                    - policy cookie -
                </a>
              </div>

        </div>
    );
}

export default FooterInfo;