import React from 'react';
import HeaderHomepage from './HeaderHomepage';
import { Link } from 'react-router-dom';

function QuattroZeroQuattro(props) {
    return (
        <div>
             <HeaderHomepage isVisible={false}></HeaderHomepage>
            <div style={{textAlign:"center",marginTop:"40px",fontSize:"30px",padding:"40px"}}>¯\_(ツ)_/¯</div>
            <div style={{textAlign:"center"}}>Not Found!!</div>
            <div style={{textAlign:"center", marginTop:"20px"}}><Link style={{color:"black",textDecoration:"underline"}} to="/search">Back to Search</Link></div>
        </div>
    );
}
export default QuattroZeroQuattro;