import React, { useState, useEffect, useReducer } from 'react';
import {NotificationManager} from 'react-notifications';
import axios from 'axios'

import { PlaceProvider, PlaceReducer, initialState } from '../Context/PlaceContext';
import MenuDetail from '../MenuDetail';
import languages from '../../utils/languages.json'
import HeaderQdiner from '../HeaderQdiner';
import Sidebar from './Sidebar';
import {gtbl, removeCookieBeforeSave, compareByPosition} from '../../utils/commons'
import Modal from 'react-modal';

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

function DetailMenuDash(props) {
    const [state, dispatch] = useReducer(PlaceReducer, initialState)
    const [isLogged, setLogged] = useState(false)
    const [listMenu, setListMenu] = useState(null)
    const [saving, setSaving] = useState(false)
    const [selectedMenu, setSelectedMenu] = useState(null)
    const [formBase, setFormBase] = useState("info")
    const [newFlowName, setNewFlowName] = useState(null)
    const [newIngredients, setNewIngredients] = useState(null)
    const [newName, setNewName] = useState(null)
    const [newPrice, setNewPrice] = useState(null)
    const [isNewPlate, setIsNewPlate] = useState(false)
    const [placeid, setPlaceid] = useState(null)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [flowId, setFlowId] = useState(null)
    const [position, setPosition] = useState(null)
    
    useEffect(() => {
        if(props.location.placeid && props.location.menu){
            setPlaceid(props.location.placeid)
            setSelectedMenu(props.location.menu)
            setFormBase("info")   
            setLogged(true)
        }else{
            window.location.href="/c/dashboard/"
        }
    }, [])

    function changeValueBaseMenu(e){
        setSelectedMenu({...selectedMenu, [e.target.name]:e.target.value})
    }

    function addNewFlow(menuid){
        if(newFlowName){
            let randomID = (Math.floor(Math.random()*10000));
            selectedMenu.flows.push({id: randomID,plates: [],lang: selectedMenu.lang,name: newFlowName,position: selectedMenu.flows.length,status:"new"})
            setSelectedMenu({...selectedMenu})
            setNewFlowName(null)
        }
    }
    function deleteFlow(e,menuid,flowid){
        e.preventDefault()
        let r = window.confirm(gtbl("confirm_delete_blank"))
        if(r){
            for(var i in selectedMenu.flows){
                if(selectedMenu.flows[i].id === flowid){
                    if(selectedMenu.flows[i]["status"] && selectedMenu.flows[i]["status"]==="new"){
                        selectedMenu.flows.splice(i,1)
                    }else{
                        selectedMenu.flows[i]["status"]="delete" 
                    }
                    break
                }
            }
            setSelectedMenu({...selectedMenu})
        }
    }
    
    function saveModifyPlate(e){
        e.preventDefault()
        addNewPlateEmpty(flowId)
    }

    function addNewPlateEmpty(flowid){
        if(isNewPlate){
            selectedMenu.flows.find(x=>x.id === flowid).plates.push({name: newName,position: selectedMenu.flows.find(x=>x.id === flowid).plates.length,price:newPrice,ingredients:newIngredients,strike:false,asterisk:false})
            setSelectedMenu({...selectedMenu})
        }else{
            var plate = selectedMenu.flows.find(x=>x.id === flowid).plates.find(x=>x.position === position)
            plate.name = newName
            plate.ingredients = newIngredients
            plate.price=newPrice
            setSelectedMenu({...selectedMenu, selectedMenu:{...selectedMenu.flows,
                    flows:{...selectedMenu.flows.plates,plate:{...selectedMenu.flows.plates,plate}}}
            })
        }
        setModalIsOpen(false)
        setIsNewPlate(false)
        setNewName("")
        setPosition(null)
        setNewIngredients("")
    }
    
    function modifyPlate(e,flowid,isNew,position){
        e.preventDefault()
        if(!isNew){
            let plate = selectedMenu.flows.find(x=>x.id === flowid).plates.find(x=>x.position === position)
            setNewName(plate.name)
            setNewIngredients(plate.ingredients)
            setNewPrice(plate.price)
            setPosition(position)
        }
        setIsNewPlate(isNew)
        setFlowId(flowid)
        setModalIsOpen(true);
    }
    function deletePlate(flowid,position){
        let r = window.confirm(gtbl("confirm_delete_blank"))
        if(r){
            selectedMenu.flows.find(x=>x.id === flowid).plates.find(x=>x.position !== position)
            for(var i in selectedMenu.flows){
                if(selectedMenu.flows[i].id === flowid){
                    for(var j in selectedMenu.flows[i].plates){
                        if(selectedMenu.flows[i].plates[j].position === position){
                            selectedMenu.flows[i].plates.splice(j,1)
                            break
                        }
                    }
                }
            }
            //PLATE NON HA ID,HA SOLO LA POSITION, ricontrollare
            setSelectedMenu({...selectedMenu})
        }
    }
    function aggiornaMenus(e){
        e.preventDefault()
        setSaving(true)
        removeCookieBeforeSave()
        delete selectedMenu['selectedMenu']
        let data = selectedMenu
        selectedMenu["placeid"]=placeid
        let token = localStorage.getItem('tk')
        //TODO: ATTENZIONE DA CORREGGERE L'ERRORE DEL SELECTED MENU SUL CAMPO data
        axios.put('/api/v1/config/menu/', data, {headers:{token:token}}).then((res)=>{
            let new_list_menu= listMenu
            for (var i in new_list_menu) {
                if (new_list_menu[i].id === selectedMenu.id) {
                    new_list_menu[i] = res.data;
                   break; //Stop this loop, we found it!
                }
              }
            setListMenu(new_list_menu)
            setSelectedMenu(res.data)
            NotificationManager.success(gtbl("success_message")); 
            setSaving(false)   
        }).catch(error=>{
            NotificationManager.error(gtbl("error_message"));
            setSaving(false)   
        })
    }
    function upPositionPlate(flowid,i){
        let positionselected = selectedMenu.flows.find(x=>x.id === flowid).plates[i].position
        let positionselected2 = selectedMenu.flows.find(x=>x.id === flowid).plates[i-1].position
        let list = selectedMenu
        list.flows.find(x=>x.id === flowid).plates[i].position = positionselected2
        list.flows.find(x=>x.id === flowid).plates[i-1].position = positionselected
        setSelectedMenu({...selectedMenu,selectedMenu:list})
        setSaving(false) 
    }
    function downPositionPlate(flowid,i){
        let positionselected = selectedMenu.flows.find(x=>x.id === flowid).plates[i].position
        let positionselected2 = selectedMenu.flows.find(x=>x.id === flowid).plates[i+1].position
        let list = selectedMenu
        list.flows.find(x=>x.id === flowid).plates[i].position = positionselected2
        list.flows.find(x=>x.id === flowid).plates[i+1].position = positionselected
        setSelectedMenu({...selectedMenu,selectedMenu:list})
        setSaving(false) 
    }
    function upPositionFlow(i){
        let positionselected = selectedMenu.flows[i].position
        let positionselected2 = selectedMenu.flows[i-1].position
        let list = selectedMenu
        list.flows[i].position =  positionselected2
        list.flows[i-1].position = positionselected
        setSelectedMenu({...selectedMenu,selectedMenu:list})
        setSaving(false) 
    }
    function downPositionFlow(i){
        let positionselected = selectedMenu.flows[i].position
        let positionselected2 = selectedMenu.flows[i+1].position
        let list = selectedMenu
        list.flows[i].position =  positionselected2
        list.flows[i+1].position = positionselected
        setSelectedMenu({...selectedMenu,selectedMenu:list})
        setSaving(false) 
    }
    function changeValuePlateBool(value,flowid,plate_position){
        var plate = selectedMenu.flows.find(x=>x.id === flowid).plates.find(x=>x.position === plate_position)
        plate["strike"] = !value
        setSelectedMenu({...selectedMenu, selectedMenu:{...selectedMenu.flows,
            flows:{...selectedMenu.flows.plates,plate:{...selectedMenu.flows.plates,plate}}}
            })
    }
    function changeNameFlow(e,flowid){
        e.preventDefault()
        for(var i in selectedMenu.flows){
            if(selectedMenu.flows[i].id === flowid){
                selectedMenu.flows[i][e.target.name] = e.target.value
                break;
            }
        }
        setSelectedMenu({...selectedMenu})
    }

    function doNothing(e){
        e.preventDefault();
    }
    function changeValueBaseMenuBool(e,value){
        setSelectedMenu({...selectedMenu, [e.target.name]:value})
    }
    return (
        <div>
            <HeaderQdiner></HeaderQdiner>
            {isLogged ?
            <>
            <Sidebar></Sidebar>
                <div className="content row">
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <div style={{maxWidth:"600px",margin:"0 auto"}}>
                            <div>
                                <form onSubmit={()=>{doNothing()}}>
                                    <div style={{padding:"15px 0"}}>
                                    {selectedMenu ?
                                    <>
                                        {!modalIsOpen ?
                                            <div style={{position:"fixed",width:"100%",maxWidth:"600px",backgroundColor:"white",zIndex:"101",top:"50px",paddingTop:"15px"}}>
                                                <button disabled={saving} className="btn btn-info" onClick={(e)=>{aggiornaMenus(e)}}>
                                                    <i className="fa fa-save"></i> {!saving? gtbl("save"):gtbl("saving")}</button> 
                                                <hr/>
                                            </div>
                                        :null}
                                        <div style={{marginTop:"50px"}}>
                                            <div className="hide_show">
                                                <button className={formBase==="info"?"btn btn-warning":"btn btn-outline-warning"} onClick={(e)=>{e.preventDefault();setFormBase("info")}} >{gtbl("show_info")}</button>
                                                <button className={formBase==="detail"?"btn btn-warning":"btn btn-outline-warning"} onClick={(e)=>{e.preventDefault();setFormBase("detail")}}>{gtbl("show_detail")}</button>
                                            </div>
                                            <div hidden={formBase !=="info" } style={{paddingTop:"15px"}}>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <input autoComplete="off"  type="checkbox" checked={selectedMenu.isActive} value={selectedMenu.isActive} name="isActive" onChange={(e)=>{changeValueBaseMenuBool(e,!selectedMenu.isActive)}} placeholder=""/>
                                                            <label className="right-checkbox">{gtbl("isVisible")}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>{gtbl("language")}</label>
                                                    <select name="lang" value={selectedMenu.lang} onChange={(e)=>{changeValueBaseMenu(e)}} className="form-control">
                                                        {languages.map((country,i)=>{
                                                            return(
                                                            <option key={i} value={country.code}>{country.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>{gtbl("name")}</label>
                                                    <input autoComplete="off"  type="text" name="title" value={selectedMenu.title} onChange={(e)=>{changeValueBaseMenu(e)}} className="form-control" placeholder=""/>
                                                </div>
                                                <div className="form-group">
                                                    <label>{gtbl("subtitle")}</label>
                                                    <input autoComplete="off"  type="text" name="subtitle" value={selectedMenu.subtitle} onChange={(e)=>{changeValueBaseMenu(e)}} className="form-control" placeholder=""/>
                                                </div>
                                                <div className="form-group">
                                                    <label>{gtbl("currency")}</label>
                                                    <input autoComplete="off"  type="text" name="currency" maxLength={3} value={selectedMenu.currency} onChange={(e)=>{changeValueBaseMenu(e)}} className="form-control" placeholder=""/>
                                                </div>
                                                <div className="form-group" hidden={true}>
                                                    <label>Position</label>
                                                    <input autoComplete="off"  type="number" name="position" value={selectedMenu.position} onChange={(e)=>{changeValueBaseMenu(e)}} className="form-control" placeholder=""/>
                                                </div>
                                                <div className="form-group">
                                                    <input autoComplete="off"  type="checkbox" checked={selectedMenu.isTastingMenu} value={selectedMenu.isTastingMenu} name="isTastingMenu"  onChange={(e)=>{changeValueBaseMenuBool(e,!selectedMenu.isTastingMenu)}} placeholder=""/>
                                                    <label className="right-checkbox">{gtbl("isTastingMenu")}</label>                                               
                                                </div>
                                                {selectedMenu.isTastingMenu ? 
                                                <div className="form-group">
                                                    <label>{gtbl("price_tasting_menu")}</label>
                                                    <input autoComplete="off"  type="number" value={selectedMenu.price_tasting_menu} name="price_tasting_menu" onChange={(e)=>{changeValueBaseMenu(e)}} className="form-control" step='0.01' placeholder='0.00'/>
                                                </div>
                                                :null}
                                                <div className="form-group" hidden={true}>
                                                    <label>Validity from</label>
                                                    <input autoComplete="off"  type="date" value={selectedMenu.validity_from ? selectedMenu.validity_from.split('T')[0] : null} name="validity_from" onChange={(e)=>{changeValueBaseMenu(e)}} className="form-control" placeholder=""/>
                                                </div>
                                                <div className="form-group" hidden={true}>
                                                    <label>Validity to</label>
                                                    <input autoComplete="off"  type="date" value={selectedMenu.validity_to ? selectedMenu.validity_to.split('T')[0] : null} name="validity_to" onChange={(e)=>{changeValueBaseMenu(e)}} className="form-control" placeholder=""/>
                                                </div>
                                                <div className="form-group">
                                                <label>{gtbl("note")}</label>
                                                    <textarea name="notes" id="" style={{width:"100%",height:"150px"}} value={selectedMenu.notes}  onChange={(e)=>{changeValueBaseMenu(e)}}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div hidden={formBase !=="detail" }>
                                                {selectedMenu.flows.filter((flow)=>{
                                                    if(!flow.status){
                                                        return flow
                                                    }
                                                    if(flow.status !== "delete"){
                                                        return flow
                                                    }
                                                
                                                }).sort(compareByPosition).map((flow,i)=>{
                                                    return(
                                                        <div style={{borderBottom:"1px solid black"}}>
                                                            <div  className="flow" style={{padding:"5px", backgroundColor:"#6e6a5e",color:"#00000"}}> 
                                                                <div className="row">
                                                                    <div className="col-2">
                                                                    <div className="" style={{maxWidth:"70px"}}>
                                                                        {i !== selectedMenu.flows.length-1 ?
                                                                        <span onClick={()=>{downPositionFlow(i)}} style={{paddingRight:"5px" , marginTop:"10px"}}>
                                                                            <i style={{color:"white"}} className="fas fa-arrow-down"></i>
                                                                        </span>
                                                                        :null}
                                                                        {i !== 0 ? 
                                                                        <span onClick={()=>{upPositionFlow(i)}} style={{ marginTop:"10px"}}>
                                                                            <i style={{color:"white"}} className="fas fa-arrow-up"></i>
                                                                        </span>
                                                                        :null}
                                                                    </div>
                                                                    </div>
                                                                    <div className="form-group col-7 input-group-sm">
                                                                        <input autoComplete="off"  type="text" name="name" value={flow.name} onChange={(e)=>{changeNameFlow(e,flow.id)}} className="input-sezioni form-control" placeholder="label"/>
                                                                    </div>
                                                                    <div className="col-3" style={{cursor:"pointer"}} onClick={(e)=>{deleteFlow(e,selectedMenu.id,flow.id)}}>
                                                                        <button style={{width:"100%"}} className="btn btn-secondary btn-sm">
                                                                            <span className={"d-none d-sm-block"}><i className="fas fa-trash-alt"></i> {gtbl("delete")}</span>
                                                                            <span className={"d-block d-sm-none"}><i className="fas fa-trash-alt"></i></span>
                                                                            </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{paddingBottom:"15px"}}>
                                                                {flow.plates.sort(compareByPosition).map((plate,i)=>{
                                                                    return(
                                                                        <div key={i} className="container-fluid" style={{padding:"0"}}>
                                                                            <div className={i%2===0 ? "flow-odd":"flow-pair"} >
                                                                                <div className="row">
                                                                                    <div className="col-9">
                                                                                        <div className="row">
                                                                                            <div className="col-8">
                                                                                            <span style={{paddingRight:"15px"}}>
                                                                                                {i !== flow.plates.length-1 ?
                                                                                                <span onClick={()=>{downPositionPlate(flow.id,i)}} style={{paddingRight:"5px" , marginTop:"10px"}}>
                                                                                                    <i className="fas fa-arrow-down"></i>
                                                                                                </span>
                                                                                                :null}
                                                                                                {i !== 0 ? 
                                                                                                <span onClick={()=>{upPositionPlate(flow.id,i)}} style={{ marginTop:"10px"}}>
                                                                                                    <i className="fas fa-arrow-up"></i>
                                                                                                </span>
                                                                                                :null}
                                                                                            </span> 
                                                                                            <span>
                                                                                                <b style={plate.strike ? {textDecoration:"line-through"} : null}>{plate.name}</b>
                                                                                            </span>
                                                                                            </div>
                                                                                            <div className="col-4" style={{textAlign:"right"}}>
                                                                                                {/* <input autoComplete="off"  type="number" name="price" value={plate.price} onChange={(e)=>{changeValuePlate(e,flow.id,plate.position)}} className="form-control" placeholder="price"/> */}
                                                                                                {gtbl("price")}: {plate.price +" "+selectedMenu.currency}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12" style={{paddingLeft:"15px"}}>
                                                                                            {/* <input autoComplete="off"  type="text" name="ingredients" value={plate.ingredients} onChange={(e)=>{changeValuePlate(e,flow.id,plate.position)}} className="form-control " placeholder="More info"/> */}
                                                                                            {plate.ingredients}
                                                                                        </div>
                                                                                        
                                                                                       
                                                                                    </div>
                                                                                    <div className="col-3">
                                                                                        <span className={"btn btn-danger btn-sm"} style={{width:"100%",marginBottom:"5px"}} onClick={()=>deletePlate(flow.id,plate.position)}>
                                                                                            <small>
                                                                                                <span className={"d-none d-sm-block"}><i className="fas fa-trash-alt"></i> {gtbl("delete")}</span>
                                                                                                <span className={"d-block d-sm-none"}><i className="fas fa-trash-alt"></i></span>
                                                                                            </small>
                                                                                        </span>
                                                                                        <span style={{width:"100%",marginBottom:"5px"}} onClick={(e)=>{modifyPlate(e,flow.id,false,plate.position)}} className={"btn btn-warning btn-sm"}>
                                                                                            <small>
                                                                                                <span className={"d-none d-sm-block"}><i className="fas fa-pen"></i> {gtbl("modify")}</span>
                                                                                                <span className={"d-block d-sm-none"}><i className="fas fa-pen"></i></span>
                                                                                            </small>    
                                                                                        </span>
                                                                                        <span style={{width:"100%",marginBottom:"5px"}} onClick={()=>{changeValuePlateBool(plate.strike,flow.id,plate.position)}} className={"btn btn-warning btn-sm"}>
                                                                                            <small>
                                                                                                <span className={"d-none d-sm-block"}>{plate.strike ? <i className="far fa-square"></i> : <i class="far fa-check-square"></i>} {plate.strike ? gtbl("finished"): gtbl("available")}</span>
                                                                                                <span className={"d-block d-sm-none"}>{plate.strike ? <i className="far fa-square"></i> : <i class="far fa-check-square"></i>}</span>
                                                                                            </small>    
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                                <button className="btn btn-info" style={{width:"100%"}} onClick={(e)=>{modifyPlate(e,flow.id,true,null)}}><i className="fas fa-plus"></i> {gtbl("add_to")} <b>{flow.name}</b></button>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div  className="flow" style={{padding:"5px", backgroundColor:"#6e6a5e",color:"#ffffff"}}> 
                                                    <div className="row">
                                                        <div className="form-group col-8 input-group-sm">
                                                            <input autoComplete="off"  type="text" name="name" value={newFlowName} onChange={(e)=>{setNewFlowName(e.target.value)}} className="form-control" placeholder={gtbl("category_name")}/>
                                                        </div>
                                                        <div className="form-group col-4 input-group-sm" style={{textAlign:"right"}}>
                                                            <button className="btn btn-info" onClick={(e)=>{e.preventDefault();addNewFlow(selectedMenu.id);setNewFlowName("")}} type="button">
                                                                <span className={"d-none d-sm-block"}><i className="fas fa-plus"></i> {gtbl("add_category")}</span>
                                                                <span className={"d-block d-sm-none"}><i className="fas fa-plus"></i></span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :null}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 d-none d-lg-block" style={{textAlign:"center",position:"fixed", right:0}}>
                        <PlaceProvider value={{state, dispatch}}>
                        <div className="marvel-device nexus5">
                            <div className="top-bar"></div>
                            <div className="sleep"></div>
                            <div className="volume"></div>
                            <div className="camera"></div>
                            <div className="screen scrolller" style={{overflowY:"scroll"}}>
                                <MenuDetail resturant={props.location.profile} image={props.location.profile.image} isDashboard={true} menu={selectedMenu}></MenuDetail>
                            </div>
                        </div>
                        </PlaceProvider>
                    </div>
                </div>
            </>
        : 
        <div className="text-center">
                <div className="spinner-border text-secondary spinner-bootstrap" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}    
        <Modal
          isOpen={modalIsOpen}
          style={customStyles}
          contentLabel="Example Modal">
            <div className="container" >
                <div className="row">
                <div className="form-group col-8 input-group-sm">
                    <label>{gtbl("name")}</label>
                    <input autoComplete="off"  required type="text" name="name" value={newName} onChange={(e)=>{setNewName(e.target.value)}} className="form-control" placeholder="label"/>
                </div>
                <div className="form-group col-4 input-group-sm">
                    <label>{gtbl("price")}</label>
                    <input autoComplete="off"  type="number" name="price" value={newPrice} onChange={(e)=>{setNewPrice(e.target.value)}} className="form-control" placeholder="price"/>
                </div>
                <div className="form-group col-12 input-group-sm">
                    <label>{gtbl("description")}</label>
                    <textarea name="ingredients" value={newIngredients} className="form-control " style={{height:"100px"}} onChange={(e)=>{setNewIngredients(e.target.value)}}  id="" cols="30" rows="10"></textarea>
                </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <button style={{width:"100%"}} className={"btn btn-info"} onClick={(e)=>{saveModifyPlate(e)}}><i className="fas fa-plus"></i> {isNewPlate ? gtbl("add") : gtbl("update")}</button>
                    </div>
                    <div className="col-6">
                        <button style={{width:"100%"}} className={"btn btn-warning"} onClick={()=>setModalIsOpen(!modalIsOpen)}>X {gtbl("close")}</button>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
    );
}

export default DetailMenuDash;