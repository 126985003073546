import React,{useEffect,useState} from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import placeholder from '../images/placeholder.png'
import HeaderHomepage from './HeaderHomepage';
import { getListPreferred, gtbl, setLangFromBrowser, getLastLanguageSelected } from '../utils/commons';
import ItemSearch from './Details/ItemSearch';
import { Helmet } from 'react-helmet';

function Search(props) {
    const [searchValue, setSearchValue] = useState("")
    const [resultSearch, setResultSearch] = useState([])
    const [listPreferred, setlistPreferred] = useState(getListPreferred)
    const [lang, setLang] = useState(null)
    const [next, setNext] = useState(null)
    const [count, setCount] = useState(0)
    const [myTimeout, setMyTimeout] = useState(null)
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        setLoading(true)
        if (searchValue.length>2){
          clearTimeout(myTimeout);
          setMyTimeout(setTimeout(() => {
              axios.get('/api/v1/place-list/?search='+searchValue).then((res)=>{
                setCount(res.data.count)
                setNext(res.data.next)
                setResultSearch(res.data.results)
                setLoading(false)
              }).catch(error=>{
                setResultSearch(null)
                setLoading(false)
              })
            },1000)
          )
        }
      }, [searchValue])
      
      useEffect(() => {
        let last_lang = getLastLanguageSelected()
        if(!last_lang){
          let res_lang = setLangFromBrowser(navigator.language)
          setLang(res_lang)
        }
        const search = props.location.search;
        const params = new URLSearchParams(search);
        const qsSearch = params.get('s');
        if(qsSearch){
          setSearchValue(qsSearch)
        }
      }, [])
    return (
        <div>
            <Helmet>
              <title>qdiner.app - find your menu today</title>
              <meta name="description" content={"qdiner.app - the easiest way to choose"}/>    
            </Helmet>
            <HeaderHomepage isVisible={false}></HeaderHomepage>
            <div className="text-center">
              <div className={ "nopadding search-active"}>
                <div className="form-group has-search">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input autoComplete="off"  type="text" value={searchValue} className="form-control" placeholder={gtbl("search")} onChange={(e)=>{setSearchValue(e.target.value)}}/>  
                </div>  
              </div>
              {searchValue.length > 2 && loading ? 
                <div className="text-center">
                  <div className="spinner-border text-secondary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                </div>
            :
            <div className="" style={{margin:"0 auto"}}>
                {resultSearch ?
                searchValue.length > 2 && resultSearch.length > 0 ? 
                <div>
                    {resultSearch.map((result,i)=>{
                    return(
                        <Link style={{textDecoration:"none",color:"#1a171c"}}  key={i} to={{
                            pathname: '/'+result.slug,
                            resturant:result,
                            backurl:"/search"
                            }}>
                            <ItemSearch
                            odd={ i % 2 ===0 ? "search-odd":"search-pair"}
                            name={result.name}
                            image={result.image}
                            city={result.city}
                            address={result.address}
                            province={result.province}
                            />
                        </Link>
                    )
                    })}
                </div>
                : <div>
                    {loading === false && resultSearch.length === 0 ?
                    gtbl("no_result"): null}
                </div>
                :
                <div>
                    {gtbl("no_connection")}
                </div>}
            </div>}
            <div className={"row"}>
                  <div className="col-12">
                    {listPreferred.length>0 ? 
                    <div className="row" > 
                      <div className="col-12" style={{textAlign:"left"}}>{gtbl("your_preferred")}</div>
                      <div className="col-12">
                      {listPreferred.map((result,i)=>{
                        return(
                          <Link style={{textDecoration:"none",color:"#1a171c"}}  key={i} to={{
                            pathname: '/'+result.u,
                            backurl:"/search"
                            }}>
                            <ItemSearch
                            odd={ i % 2 ===0 ? "search-odd":"search-pair"}
                            name={result.n}
                            image={result.i}
                            city={result.c}
                            address={result.a}
                            province={result.p}
                            />
                        </Link>
                        )
                      })}
                      </div>
                    </div>
                  :null}
                  </div>
                </div>
        </div>
    </div>
    );
}

export default Search;