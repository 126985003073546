import React,{useEffect,useState} from 'react';
import {getListPreferred,getPreferredBySlug,addItemToPreferred,removeItemToPreferred} from '../../utils/commons'

function FabSave(props) {
    const [saved, setsaved] = useState(false)
    const [list, setList] = useState(getListPreferred())
    useEffect(() => {
        try {
            if(list && getPreferredBySlug(props.resturant.slug) !== -1){
                setsaved(true)
            }
        } catch (error) {
           console.log("errore lista preferiti")
        }
    }, [])
    function changeStatus(){
        let item={u:props.resturant.slug,n:props.resturant.name,i:props.resturant.image,a:props.resturant.address,c:props.resturant.city,p:props.resturant.province}
        if(saved){
            setList(removeItemToPreferred(item))
        }else{
            setList(addItemToPreferred(item))
        }
        setsaved(!saved)
    }
    return (
        <span onClick={()=>changeStatus()}>
            <i className={ saved ? "fas fa-heart fa-heart-active" : "far fa-heart"} style={{fontSize:"25px",color:"#1a171c"}}></i>
        </span>
    );
}

export default FabSave;