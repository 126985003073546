import React from 'react';
import HeaderHomepage from '../HeaderHomepage';

function TermAndConditions(props) {
    return (
        <div>
            <HeaderHomepage isVisible={false}></HeaderHomepage>
            <div style={{textAlign:"left"}}>
            <p>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}><i><b>CONDIZIONI
            GENERALI DI CONTRATTO</b></i></font></font></font></p>
            <p align="justify" style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <br/>

            </p>
            <p align="justify" style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>Gli
            Utenti che utilizzano i servizi offerti da </font></font></font><font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}><b>qdiner.app
            </b></font></font></font><font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>dichiarano
            di conoscere ed accettare le presenti condizioni generali di
            contratto. Accettandole l&rsquo;utente dichiara di essere
            maggiorenne.</font></font></font></p>
            <p style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Times New Roman, serif"><font size="3" style={{fontSize:"12pt"}}><b>Informazioni
            generali </b></font></font></font>
            </p>
            <p align="justify" style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>Le
            presenti condizioni generali definiscono i termini e condizioni di
            utilizzo dei servizi offerti da </font></font></font><font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}><b>The
            Lab </b></font></font></font><font color="#333333"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}><b>di
            Paolo Scattoni &amp; C. s.a.s.</b></font></font></font><font color="#333333"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>,
            con sede legale in Via della Fontina, 2&nbsp; - 53043 Chiusi
            (SI),&nbsp;</font></font></font><a href="mailto:paolo.scattoni@thelabitalia.it"><font color="#000000"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}><span style={{textDecoration: "none"}}>paolo.scattoni@thelabitalia.it</span></font></font></font></a><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>&nbsp;</font></font><font color="#333333"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>-&nbsp;p.iva
            e cod. fisc. 01380370526, </font></font></font><font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>iscritta
            al registro delle imprese con n. REA SI - 143267, n. tel. +39 0578
            227271 &nbsp;(di seguito &ldquo;</font></font></font><font color="#333237"><font face="Times New Roman, serif"><font size="3" style={{fontSize:"12pt"}}><b>The
            Lab&rdquo;</b></font></font></font><font color="#333237"><font face="Times New Roman, serif"><font size="3" style={{fontSize:"12pt"}}>
            /&ldquo;</font></font></font><font color="#333237"><font face="Times New Roman, serif"><font size="3" style={{fontSize:"12pt"}}><b>Fornitore</b></font></font></font><font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>&rdquo;).</font></font></font></p>
            <p align="justify" style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>The
            Lab gestisce il sito </font></font></font><a href="https://qdiner.app/"><font color="#000000"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}><span style={{textDecoration: "none"}}>https://qdiner.app</span></font></font></font></a><font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>
            che offre la digitalizzazione del men&ugrave; e altri servizi ad esso
            correlati. </font></font></font>
            </p>
            <p align="justify" style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <br/>

            </p>
            <p align="justify" style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>The
            Lab mette a disposizione la piattaforma in cui i Consumatori finali
            possono prendere visione dei men&ugrave; e/o servizi dei vari
            Ristoratori e/o aziende presenti (&ldquo;</font></font></font><font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>Clienti</b></font></font></font><font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>&rdquo;)
            ma non possono acquistare Men&ugrave; e/o effettuare ordini e/o
            effettuare pagamenti di servizi. Pertanto, tutti i contratti
            stipulati per l&rsquo;acquisto dei Men&ugrave; sono conclusi
            esclusivamente tra il Cliente e il Consumatore Finale del Men&ugrave;
            e non con The Lab che non &egrave; parte di tale rapporto
            contrattuale.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <br/>

            </p>
            <p align="justify" style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>Il
            Ristoratore (&ldquo;</font></font></font><font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>Cliente</b></font></font></font><font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>&rdquo;)
            &egrave; il solo responsabile della messa online dei Men&ugrave; e
            della gestione degli eventuali reclami o di qualsiasi altra questione
            derivante da o connessa al contratto concluso tra lo stesso ed il
            Consumatore Finale.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.1in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>Attraverso
            l'accesso o l'utilizzo dei Servizi offerti da The Lab, il Cliente
            accetta di essere vincolato dal presente accordo e d&agrave; atto di
            aver letto e compreso i relativi termini. E&rsquo; necessario leggere
            le condizioni di contratto attentamente poich&eacute; contengono
            informazioni circa i diritti del Cliente e relative limitazioni,
            nonch&eacute; una sezione riguardante la legge e la giurisdizione
            applicabili in caso di dispute. L&rsquo;accettazione potr&agrave;
            avvenire cliccando sull&rsquo;apposito pulsante: in caso di mancata
            accettazione di tutti i termini e condizioni, il Cliente non sar&agrave;
            autorizzato all'uso dei Servizi. </font></font></font>
            </p>
            <p align="center" style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>Premesso:</b></font></font></font></p>
            <ul>
                <li/><p align="justify" style={{marginBottom: "0in", lineHeight: "91%", background: "#ffffff"}}>
                <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>che
                il Fornitore gestisce il sito </b></font></font></font><a href="https://qdiner.app/"><font color="#000000"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}><span style={{textDecoration: "none"}}>https://qdiner.app</span></font></font></font></a><font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>
                ;</b></font></font></font></p>
                <li/><p align="justify" style={{marginBottom: "0.1in", lineHeight: "91%", background: "#ffffff"}}>
                <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>che,
                con l&rsquo;espressione &ldquo;Condizioni Generali di contratto&rdquo;
                si intende il contratto di compravendita relativo ai servizi che il
                Fornitore offre al Cliente sul sito qdiner.app;</b></font></font></font></p>
                <li/><p align="justify" style={{marginBottom: "0.1in", lineHeight: "91%", background: "#ffffff"}}>
                <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>che
                il marchio e il logo relativi al sito e ai servizi sono di esclusiva
                propriet&agrave; del Fornitore;</b></font></font></font></p>
                <li/><p align="justify" style={{marginBottom: "0in", lineHeight: "91%", background: "#ffffff"}}>
                <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>che
                le presenti Condizioni Generali di contratto disciplinano la vendita
                online tra la&nbsp;The Lab ed il Cliente che dichiara espressamente
                di compiere l&rsquo;acquisto per fini che rientrano nel quadro della
                sua attivit&agrave; commerciale, industriale, artigianale o
                professionale;</b></font></font></font></p>
                <li/><p align="justify" style={{marginBottom: "0in", lineHeight: "91%", background: "#ffffff"}}>
                </p>
                <li/><p align="justify" style={{marginBottom: "0.1in", lineHeight: "91%", background: "#ffffff"}}>
                <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>che
                le presenti premesse costituiscono parte integrante e sostanziale
                del contratto;</b></font></font></font></p>
            </ul>
            <p align="center" style={{marginBottom: "0in", lineHeight: "91%", background: "#ffffff"}}>
            <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>si
            conviene quanto segue:</b></font></font></font></p>
            <p align="center" style={{marginBottom: "0.1in", lineHeight: "100%", background: "#ffffff"}}>
            <br/>
            <br/>

            </p>
            <p style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>1.
            Oggetto del contratto</b></font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>1.1.
            Le presenti condizioni generali, che vengono messe nella
            disponibilit&agrave; del Cliente per la riproduzione e conservazione
            ai sensi dell&rsquo;art. 12, D.Lgs. 9 aprile 2003, n. 70, hanno per
            oggetto l&rsquo;acquisto di servizi, per mezzo di rete telematica,
            tramite il Sito internet gestito dal Fornitore The Lab, come sopra
            identificato;</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>1.2.
            Con il presente contratto, rispettivamente, il Fornitore vende e il
            Cliente acquista a distanza, per mezzo di rete telematica, i servizi
            indicati ed offerti in vendita sul Sito del Fornitore.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>1.3.
            Le caratteristiche principali dei servizi di cui al punto precedente
            sono illustrate sul Sito del Fornitore.</font></font></font></p>
            <p style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>1.4.
            Il Fornitore si impegna a fornire i servizi selezionati dal Cliente
            dietro il pagamento del corrispettivo di cui all&rsquo;art. 3 del
            presente contratto.</font></font></font></p>
            <p style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>2.
            Accettazione delle condizioni generali di contratto </b></font></font></font>
            </p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>2.1.
            Tutti gli ordini di acquisto saranno inoltrati dal Cliente al
            Fornitore attraverso il completamento della procedura di acquisto
            indicata sul sito.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>2.2.
            Le presenti Condizioni Generali di Vendita e l&rsquo;Informativa
            Privacy devono essere esaminate &ldquo;on line&rdquo; dal Cliente,
            prima del completamento della procedura di acquisto. L&rsquo;accettazione
            delle Condizioni Generali di Vendita avviene tramite selezione della
            casella recante la dicitura &ldquo;Accettazione Condizioni Generali
            di contratto&rdquo;. Al Cliente verr&agrave; altres&igrave;
            sottoposta l&rsquo;informativa Privacy, in relazione alla quale verr&agrave;
            chiesto, ove necessario, di prestare o negare il proprio consenso al
            trattamento dei dati personali. &nbsp;L&rsquo;inoltro dell&rsquo;ordine
            d&rsquo;acquisto da parte del Cliente, pertanto, implica la totale
            conoscenza delle stesse e loro integrale accettazione.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>2.3.
            Il Cliente, al termine dei 14 gg. gratuiti di prova del servizio,
            inviando telematicamente il proprio ordine d&rsquo;acquisto accetta
            incondizionatamente e si obbliga ad osservare, nei suoi rapporti con
            il Fornitore, le condizioni generali e di pagamento di seguito
            illustrate, dichiarando di aver preso visione e di confermare tutte
            le indicazioni da lui fornite ai sensi delle norme sopra richiamate,
            prendendo altres&igrave; atto che il Fornitore non &egrave; vincolato
            a condizioni diverse, se non preventivamente concordate per iscritto.</font></font></font></p>
            <p style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>3.
            Prezzi di vendita, modalit&agrave; d&rsquo;acquisto</b></font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>3.1
            L&rsquo;eventuale acquisto &egrave; preceduto da un periodo gratuito
            di 14 giorni, alla fine del quale il Cliente potr&agrave; decidere di
            effettuate l&rsquo;ordine per un mese, per sei mesi o per un anno
            oppure non procedere ad alcun ordine.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>3.2.
            I prezzi di vendita presenti sul Sito, espressi in Euro, si intendono
            IVA esclusa e sono i seguenti: Attenzione il sito deve corrispondere
            ai prezzi, non so se messi cos&igrave; possono tornare perch&eacute;
            sul sito in prima battuta ci sono i 2 prezzi mensili: </font></font></font>
            </p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>-
            &euro; 14,90 + iva mensili nel caso di acquisto mensile;</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>-
            &euro; 11,90 + iva mensili nel caso di acquisto di abbonamento
            semestrale;</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>-
            &euro; 118,80 + iva annuali nel caso di acquisto annuale
            (corrispondente ad &euro; 9,90 + iva  mensili); </font></font></font>
            </p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>3.3.
            Il contratto di acquisto (di seguito &quot;</font></font></font><font color="#333237"><font face="Times New Roman, serif"><font size="3" style={{fontSize:"12pt"}}><b>Ordine</b></font></font></font><font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>&quot;)
            si perfeziona nel momento in cui il Cliente effettua il pagamento del
            servizio presente sul Sito, previa ricezione da parte dello stesso di
            una mail con cui il Fornitore conferma l&rsquo;ordine. Il Cliente,
            ricevuta la conferma dell&rsquo;ordine tramite mail, si impegna a
            verificarne immediatamente la correttezza, nonch&eacute; a comunicare
            entro 24 ore eventuali richieste di modifica. Il perfezionamento
            dell&rsquo;acquisto implica l'accettazione delle presenti Condizioni
            Generali.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>3.4.
            Tutti servizi offerti in vendita da The Lab sono tutelati dal diritto
            d&rsquo;autore e ne risulta conseguentemente vietata ogni attivit&agrave;
            volta a modificare, copiare, riprodurre, distribuire, trasmettere o
            diffondere il contenuto senza il consenso dei titolari del suddetto
            diritto.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>3.5.
            Ai sensi dell&rsquo;art. 12 del D.Lgs. 70/2003, il Fornitore informa
            il Cliente che ogni ordine inviato viene conservato in forma digitale
            e/o cartacea sul server presso la sede del Fornitore stesso secondo
            criteri di riservatezza e sicurezza.</font></font></font></p>
            <p style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>4.
            Modalit&agrave; di pagamento ed emissione fattura</b></font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>4.1.
            Ogni pagamento da parte del Cliente potr&agrave; avvenire unicamente
            per mezzo di uno dei metodi indicati: </font></font></font>
            </p>
            <p style={{textIndent: "0.49in", marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            </p>
            <p style={{textIndent: "0.49in", marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            </p>

            <p align="justify" style={{textIndent: "0.49in", marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>a)
            pagamento online tramite PayPal, carta di credito: il Cliente verr&agrave;
            reindirizzato sul sito di Paypal, sul quale potr&agrave; pagare con
            il proprio conto Paypal oppure con carta di credito.</font></font></font></p>
            <p style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>4.2.
            Il Fornitore emetter&agrave; la fattura e questa sar&agrave; inviata
            in formato elettronico al Cliente.</font></font></font></p>
            <p style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>5.
            Modalit&agrave; di fornitura dei servizi</b></font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>5.1.
            Il Fornitore provveder&agrave; a fornire i servizi al Cliente, con le
            modalit&agrave; e nel termine indicati sul sito web e nelle presenti
            condizioni di contratto.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <br/>
            <br/>

            </p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>5.2.
            Sar&agrave; cura del Fornitore avvertire via mail, a partire da 7 gg.
            prima della scadenza del servio, il Cliente riguardo il necessario
            rinnovo pena l&rsquo;interruzione del servizio</font></font></font></p>
            <p style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237">&nbsp;</font><font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>6.
            Limitazioni di responsabilit&agrave;</b></font></font></font></p>
            <p style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>6.1.
            Il Fornitore non sar&agrave; soggetto a responsabilit&agrave;
            contrattuale od extracontrattuale verso il Cliente salvo il caso di
            dolo o colpa grave. </font></font></font>
            </p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>6.2.
            Il Fornitore, in particolare, non sar&agrave; responsabile per:</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>-
            eventuali perdite di opportunit&agrave; commerciale e qualsiasi altra
            perdita (ad esempio perdita di ricavi, di incassi, profitti, di
            contratti, di clientela, di rapporti commerciali ecc), anche
            indiretta, eventualmente subita dal Cliente che non siano conseguenza
            diretta della violazione del contratto da parte del Fornitore.</font></font></font></p>
            <p style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>-
            errato o inidoneo utilizzo del sito web da parte del Cliente o di
            terzi;</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>-
            emissione di documenti fiscali od indicazione di dati fiscali errati
            a causa di errori relativi ai dati ed alle informazioni fornite dal
            Cliente, essendo quest&rsquo;ultimo l&rsquo;unico responsabile del
            corretto inserimento;</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>-
            mancato o ritardato adempimento delle proprie obbligazioni e/o per
            danni contrattuali od extracontrattuali dovuti ad eventi di forza
            maggiore o, comunque, ad eventi imprevisti ed imprevedibili ed
            indipendenti dalla sua volont&agrave;, quali ad esempio, a titolo non
            esaustivo, guasti, interruzioni o malfunzionamenti delle linee
            telefoniche o elettriche, della rete internet e/o di altri strumenti
            di trasmissione, indisponibilit&agrave; di siti web, scioperi, eventi
            naturali, virus, attacchi informatici, ecc.</font></font></font></p>
            <p style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>7.
            Garanzie e modalit&agrave; di assistenza</b></font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>7.1.
            Il sito web viene fornito &ldquo;cos&igrave; come si presenta&rdquo;
            e &ldquo;come &egrave; disponibile&rdquo; ed il Fornitore non
            fornisce alcuna garanzia esplicita o implicita in relazione al sito
            web, n&eacute; fornisce alcuna garanzia che il sito web potr&agrave;
            soddisfare le esigenze e gli scopi particolari dei Clienti estranei
            alla causa del contratto o che il servizio non avr&agrave; mai
            interruzioni o sar&agrave; privo di errori o che sar&agrave; privo di
            virus o bug.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>7.2.
            Il Fornitore si adoperer&agrave; per assicurare che il sito sia
            disponibile ininterrottamente 24 ore al giorno, ma non potr&agrave;
            in alcun modo essere ritenuto responsabile se, per qualsiasi motivo,
            il sito web non fosse accessibile e/o operativo in qualsiasi momento
            o per qualsiasi periodo. L&rsquo;accesso al sito web pu&ograve;
            essere sospeso temporaneamente e senza preavviso in caso di guasto
            del sistema, necessit&agrave; di manutenzione, riparazioni o per
            ragioni del tutto estranee alla volont&agrave; del Fornitore o per
            eventi di forza maggiore.</font></font></font></p>
            <p style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237">&nbsp;</font><font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>8.
            Registrazione e creazione di un proprio Account</b></font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>8.1.
            Per usufruire dei Servizi del sito </font></font></font><a href="https://qdiner.app/"><font color="#0000ff"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}><u>https://qdiner.app</u></font></font></font></a><font color="#000000"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}><span style={{textDecoration: "none"}}>
            </span></font></font></font><font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>&egrave;
            necessario registrarsi alla Piattaforma e creare quindi un proprio
            account personale (l&rsquo;&ldquo;</font></font></font><font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>Account</b></font></font></font><font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>&rdquo;).
            Fornendo alcune informazioni di carattere personale, indicando un
            valido indirizzo e-mail e, in generale, seguendo la procedura di
            registrazione illustrata di volta in volta ed accettare integralmente
            la privacy policy e le Condizioni.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>8.2.
            L&rsquo;Account &egrave; considerato personale ai fini dell&rsquo;acquisto
            dei servizi disponibili sulla Piattaforma e non potr&agrave; quindi
            essere utilizzato per conto di altre persone fisiche o giuridiche.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>8.3.
            I Clienti registrati si impegnano ad aggiornare tempestivamente i
            dati forniti in sede di registrazione in caso di modifiche e
            variazioni intervenute successivamente. Gli stessi saranno i soli
            responsabili della veridicit&agrave;, completezza, precisione e
            dell&rsquo;aggiornamento di tali informazioni.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>9.2.
            Il Cliente mantiene la piena titolarit&agrave; dei dati e materiali
            da lui forniti (con &ldquo;materiali&rdquo; s&rsquo;intende, a titolo
            d&rsquo;esempio non esaustivo: testi, loghi, marchi, immagini,
            audiovisivi, documenti, grafici, schemi, progetti), siano anche essi,
            all&rsquo;insaputa del Fornitore, sensibili, personali, o coperti da
            un qualsivoglia diritto (ivi incluso quelli d&rsquo;autore),
            assumendosi ogni responsabilit&agrave; in ordine alla loro gestione,
            con espresso esonero del Fornitore da ogni responsabilit&agrave; e
            onere di accertamento e/o controllo al riguardo.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>9.3.
            Il Cliente utilizza i servizi a proprio rischio, esonerando il
            Fornitore nei confronti di ogni parte per controversie legali/civili
            o amministrative, danni indiretti, specifici, incidentali, punitivi,
            cauzionali o consequenziali (a titolo esemplificativo non esclusivo:
            danni in caso d&rsquo;impossibilit&agrave; di utilizzo o accesso ai
            servizi, perdita o corruzione di dati, di profitti, danni d&rsquo;immagine,
            interruzioni dell&rsquo;attivit&agrave; o simili), causati
            dall&rsquo;utilizzo o dall&rsquo;impossibilit&agrave; di utilizzare i
            servizi e basati su qualsiasi ipotesi di responsabilit&agrave;.
            Inoltre il Cliente esonera il Fornitore da ogni responsabilit&agrave;
            correlata a mancati adeguamenti che si rendessero obbligatori ai
            sensi di leggi, normative e direttive, ci&ograve; significando che &egrave;
            onere del Cliente informarsi e predisporre gli adempimenti necessari
            e/o richiesti dalla Legge. </font></font></font>
            </p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>9.4.
            Il Cliente esonera il Fornitore da ogni responsabilit&agrave; per
            eventuali malfunzionamenti dei servizi, causati da problemi tecnici
            su hosting, macchinari, server, router, linee di sua propriet&agrave;
            o di societ&agrave; terze selezionate per fornire quanto previsto in
            questo contratto.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>9.5.
            Il Cliente esonera il Fornitore da ogni responsabilit&agrave; per
            perdite di dati, diffusione accidentale di dati anche personali o
            sensibili, e qualsiasi altro tipo di danno verificatosi a seguito di
            attacchi da parte di pirati informatici, ladri, hacker e/o virus.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>9.6.
            Il Cliente esonera il Fornitore da ogni responsabilit&agrave; per il
            malfunzionamento dei servizi a causa di non conformit&agrave; e/o
            obsolescenza degli apparecchi dei quali il Cliente o terze parti sono
            dotati. Il Cliente dichiara di essere a conoscenza dei requisiti
            minimi per una visualizzazione ottimale del servizio e solleva il
            Fornitore da ogni responsabilit&agrave; derivante da incompatibilit&agrave;
            con alcuni sistemi o browser non aggiornati. In considerazione
            dell&rsquo;elevato grado di soggezione ad obsolescenza e innovazione
            dei presenti servizi, essi si intendono approvati e definiti, senza
            ulteriori pretese, all&rsquo;atto del pagamento.</font></font></font></p>
            <p style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>10.
            Diritti di Propriet&agrave; Intellettuale </b></font></font></font>
            </p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>10.1.
            I marchi e /o altri segni distintivi presenti sul Sito sono di
            esclusiva propriet&agrave; di The Lab e non possono essere alterati,
            copiati, rimossi o cancellati, salvo autorizzazione scritta del
            Fornitore. Qualunque infrazione di questa condizione potrebbe
            costituire violazione del copyright, dei marchi registrati o di altri
            diritti di propriet&agrave; intellettuali e potrebbero portare
            all&rsquo;applicazione di sanzioni civili e/o penali.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>10.2
            I contenuti disponibili su qdiner.app sono protetti dalla legge sul
            diritto d&rsquo;autore e dalle altre norme interne ed internazionali
            poste a tutela dei diritti di propriet&agrave; intellettuale:
            pertanto &egrave; fatto espresso divieto al Cliente di copiare e/o
            scaricare e/o condividere, modificare, pubblicare, trasmettere,
            vendere, cedere a terzi i contenuti, anche di terzi, disponibili sul
            sito, o consentire che soggetti terzi lo facciano. </font></font></font>
            </p>
            <p style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>11.
            Clausola risolutiva espressa</b></font></font></font></p>
            <p align="justify" style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>11.1.
            Il contratto si risolve di diritto, ex art. 1456 C.C., autorizzando
            il Fornitore a interrompere i servizi senza preavviso qualora il
            Cliente: a) ceda in tutto o in parte i servizi a terzi senza il
            preventivo consenso del Fornitore; b) non provveda al pagamento delle
            somme o non rispetti i termini stabiliti; c) sia sottoposto o ammesso
            a una procedura concorsuale.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <br/>

            </p>
            <p align="justify" style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>12.
            Comunicazioni</b></font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>12.1.
            Fatti salvi i casi espressamente indicati, o stabiliti da obblighi di
            legge, le comunicazioni tra il Fornitore e il Cliente avranno luogo
            preferibilmente per il tramite di messaggi e-mail ai rispettivi
            indirizzi elettronici che saranno da entrambe le parti considerati
            valido mezzo di comunicazione e la cui produzione in giudizio non
            potr&agrave; essere oggetto di contestazione per il solo fatto di
            essere documenti informatici.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>12.2.
            Le comunicazioni scritte dirette al Fornitore, nonch&eacute; gli
            eventuali reclami saranno ritenuti validi unicamente ove inviati
            all&rsquo;indirizzo di The Lab indicato nella parte informazioni
            generali. </font></font></font>
            </p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>12.3.
            Entrambe le parti potranno in qualsivoglia momento cambiare il
            proprio indirizzo e-mail ai fini del presente articolo, purch&eacute;
            ne diano tempestiva comunicazione all&rsquo;altra parte.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>13.
            Collegamento a siti di terzi</b></font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>Il
            sito web potrebbe contenere collegamenti a siti/applicazioni di
            terzi. Il Fornitore non esercita alcun controllo su di essi e,
            pertanto, non &egrave; in alcun modo responsabile per i contenuti di
            questi siti/applicazioni.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>Alcuni
            di questi collegamenti potrebbero rinviare a siti/applicazioni di
            terzi che forniscono servizi attraverso il sito web. In questi casi,
            ai singoli servizi si applicheranno le condizioni generali per l&rsquo;uso
            del sito/applicazione e per la fruizione del servizio predisposte dai
            terzi, rispetto alle quali il Fornitore non assume alcuna
            responsabilit&agrave;.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>14.
            Uso improprio del sito ed utilizzi vietati</b></font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>14.1.
            I Clienti sono responsabili per i contenuti propri o di terzi che
            condividono con qualsiasi modalit&agrave; su qdiner.app: essi
            manlevano il Fornitore da ogni responsabilit&agrave; in relazione
            alla diffusione illecita di contenuti di terzi o all&rsquo;utilizzo
            di qdiner.app in modo contrario alla legge. Il Fornitore non svolge
            alcun tipo di verifica dei contenuti pubblicati dall&rsquo;Utente o
            da terzi.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>14.2.
            Al Cliente &egrave; vietato l&rsquo;utilizzo del sito in modo
            fraudolento con l&rsquo;introduzione di virus, trojan horse, bachi
            informatici, bombe logiche, time bomb, registratori di digitazione,
            programmi spia, software contenti pubblicit&agrave;, o altri
            elementi, programmi o codici che pregiudichino il funzionamento dei
            software o delle apparecchiature informatiche (o progettati con tale
            finalit&agrave;); e/o con l&rsquo;accesso o tentando l&rsquo;accesso
            non autorizzato al server che ospita il Sito, o a qualsiasi altro
            server, computer o database collegato al Sito; e/o con l&rsquo;interruzione
            del servizio del Sito con attacchi informatici isolati o lanciati
            contemporaneamente da pi&ugrave; fonti; e/o diffondendo contenuti e/o
            immagini coperti da diritti d&rsquo;autore o contrari alla legge, o
            ingannevoli o discriminatori o fraudolenti od offensivi od osceni. </font></font></font>
            </p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>14.3.La
            violazione di questa clausola potendo avere rilievo sotto il profilo
            della responsabilit&agrave; penale potr&agrave; essere denunciata
            all&rsquo;autorit&agrave; giudiziaria con conseguente comunicazione
            del nominativo del Cliente che se ne sia reso responsabile.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>14.4.
            Tali violazioni comporteranno la perdita immediata del diritto di
            utilizzare il Sito, riservandosi il Fornitore il diritto di
            sospendere o cancellare l&rsquo;account dell&rsquo;Utente in
            qualsiasi momento e senza preavviso se ritiene che: il Fornitore
            abbia violato il contratto; l&rsquo;accesso al servizio potrebbe
            causare danni al Fornitore, ad altri utenti o a terzi; l&rsquo;uso di
            qdiner.app possa violare la legge o i regolamenti applicabili; in
            caso di investigazioni condotte in esito ad azioni legali o per per
            ordine della pubblica autorit&agrave;; l&rsquo;account venga ritenuto
            dal Titolare inappropriato od offensivo. </font></font></font>
            </p>
            <p style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>15.
            Trattamento dei dati personali</b></font></font></font></p>
            <p align="justify" style={{marginBottom: "0.1in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>15.1.Il
            Cliente dichiara espressamente di aver preso visione dell&rsquo;informativa
            sul trattamento dei dati personali ai sensi dell&rsquo;art. 13 del
            Regolamento (UE) 2016/679 e della policy del Sito. I diritti
            derivanti dalla normativa sulla privacy e gli obblighi di informativa
            da essa nascenti a carico del Fornitore sono esaminati &ldquo;on
            line&rdquo;, prima del completamento della procedura di acquisto.
            L&rsquo;inoltro della conferma d&rsquo;ordine pertanto implica totale
            conoscenza degli stessi.</font></font></font></p>
            <p style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>16.
            Legge applicabile e foro competente</b></font></font></font></p>
            <p style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>16.1.
            Il presente contratto &egrave; regolato dalla legge italiana.</font></font></font></p>
            <p style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>16.2.
            In caso di controversie derivanti dal presente contratto o ad esso
            collegate, le parti si impegnano a cercare un equo e bonario
            componimento. Le parti considerano quale luogo di conclusione del
            contratto la sede legale del Fornitore. Eventuali controversie
            saranno di competenza del Foro di Siena.</font></font></font></p>
            <p style={{marginBottom: "0in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="inherit, serif"><font size="3" style={{fontSize:"12pt"}}><b>17.
            Clausole finali</b></font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>17.1.
            Il presente contratto abroga e sostituisce ogni accordo, intesa,
            negoziazione, scritta od orale, intervenuta in precedenza tra le
            parti e concernente l&rsquo;oggetto di questo contratto.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>17.2.
            L&rsquo;eventuale inefficacia di talune clausole non inficia la
            validit&agrave; dell&rsquo;intero contratto.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>17.3
            Eventuali modifiche o integrazioni alle presenti Condizioni Generali
            di Vendita potranno avvenire unicamente per iscritto.</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.08in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>17.4.
            Per quanto non esplicitamente esposto si richiamano le norme del
            Codice Civile.</font></font></font></p>
            <p align="center" style={{marginBottom: "0.1in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>****</font></font></font></p>
            <p align="justify" style={{marginBottom: "0.1in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>Ai
            sensi e per gli effetti degli articoli 1341 e 1342 c.c., l&rsquo;Cliente
            dichiara di aver attentamente letto il contratto e di approvare
            espressamente le seguenti clausole:</font></font></font></p>
            <p style={{marginBottom: "0.1in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>Art.
            6 (Limitazioni di responsabilit&agrave;)</font></font></font></p>
            <p style={{marginBottom: "0.1in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>Art.
            7 (Garanzie e modalit&agrave; di assistenza),</font></font></font></p>
            <p style={{marginBottom: "0.1in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>Art.
            9 (Consegna di materiale al Fornitore e responsabilit&agrave; del
            Cliente),</font></font></font></p>
            <p style={{marginBottom: "0.1in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>Art.
            10 (Diritto di Propriet&agrave; Intellettuale ed utilizzi vietati)</font></font></font></p>
            <p style={{marginBottom: "0.1in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>Art.
            11 (Clausola risolutiva espressa),</font></font></font></p>
            <p style={{marginBottom: "0.1in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>Art
            13 (Collegamento a siti di terzi),</font></font></font></p>
            <p style={{marginBottom: "0.1in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>Art.
            14 (Uso improprio del sito ed utilizzi vietati)</font></font></font></p>
            <p style={{marginBottom: "0.1in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237"><font face="Arial, serif"><font size="3" style={{fontSize:"12pt"}}>Art.
            16 (Legge applicabile e foro competente)</font></font></font></p>
            <p style={{marginBottom: "0.1in", lineHeight: "100%", background: "#ffffff"}}>
            <font color="#333237">&nbsp;</font></p>
            <p style={{marginBottom: "0.14in", lineHeight: "100%"}}><br/>
            <br/>

            </p>
            </div>
        </div>
    );
}

export default TermAndConditions;