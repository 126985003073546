import React from 'react';
import FabSave from './Details/FabSave';

function Header(props) {
    return (
        <div className="resturant-header" style={props.isDashboard ? {position:"relative"}:null}>
            <div className="background-grey" style={{textAlign:"center"}}>
                    {!props.isDashboard ?
                    <div className="col-lg-6 col-md-8 col-sm-10 co-xs-12 padding-header" style={{margin:"0 auto"}}>
                        <div className="col-12 nopadding-header resturant-name-div" >
                            <div className="fixed-width-header">
                                <span className="resturant-name" style={{textAlign:"center",fontSize:"20px"}}>
                                    <a className="link-app" href={props.backurl}><i className="fas fa-arrow-left"></i></a> 
                                </span>
                                <span className="resturant-name" style={{marginLeft:"15px"}}>
                                    <a className="link-app" href={props.homeurl}>
                                        <img className="image-logo" src={props.resturant.image} alt=""/>
                                    </a>
                                </span>
                            </div>
                            <div className="variable-width-header">
                                <span>
                                    <div className="resturant-name">{props.resturant.name}</div>
                                </span>
                            </div>
                            <div className="fixed-width-header2" style={{paddingTop:"5px"}}>
                                <FabSave resturant={props.resturant}></FabSave>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-12 nopadding-header">
                        <div className="fixed-width-search">
                            <span className="resturant-name" style={{marginLeft:"15px",marginTop:"3px"}}>
                                <img src={props.resturant ? props.resturant.image : props.image} alt="" className="image-logo"/>
                            </span>
                        </div>
                        <div className="variable-width-search" style={{textAlign:"left"}}>
                            <span className="resturant-name">{props.resturant ? props.resturant.name : props.name}</span>
                        </div>
                    </div>
                    }
                
            </div>
        </div>
    );
}

export default Header;