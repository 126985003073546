import React from 'react';
import Plate from './Plate';
import { compareByPosition } from '../utils/commons';

function Flows(props) {
    return (
        <div>
            {props.flows && props.flows.sort(compareByPosition).map((flow,i)=>{
                return(
                    <div key={i}>
                        {flow.plates && (flow.plates.length > 0 || props.isDashboard)? 
                            <div>
                                <div className="flow background-menu-item-head"  hidden={flow.status === "delete"}>
                                    <b>{flow.name}</b>
                                </div>
                                {flow.status !== "delete" && flow.plates.sort(compareByPosition).map((plate,i)=>{
                                    return(
                                        <Plate isTastingMenu={props.isTastingMenu} currency={props.currency} showAll={props.showAll} key={i} plate={plate}></Plate>
                                    )    
                                })}
                            </div>
                        :null}
                    </div>
                    )
                })}
        </div>
    );
}

export default Flows;