import React from 'react';
import {Helmet} from "react-helmet";

function CustomHelmet(props) {
    return (
        <Helmet>
            <title>{props.title ? props.title : "the easiest way to choose" } - qdiner.app </title>
            <meta name="description" content={props.title ? props.title + " menus powered by qdiner.app" : "qdiner.app - the easiest way to choose"}/>    
            {props.noindex ? <meta NAME="robots" CONTENT="noindex" />:null}
        </Helmet>
    );
}

export default CustomHelmet;