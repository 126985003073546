import React from 'react';
import placeholder from '../../images/placeholder.png'

function ItemSearch(props) {
    return (
        <div>
            <div className={props.odd} style={{position:"relative"}}>
                <div className="fixed-width-search resturant-name">
                    <img className="img-search image-logo" src={props.image ? props.image : placeholder} alt={props.name}/>
                </div>
                <div style={{textAlign:"left",paddingLeft:"5px"}} className="variable-width-search">
                    <div>{props.name}</div>
                    {props.address || props.city || props.province ?
                    <>
                        <div style={{fontSize:"13px"}}>{props.address} 
                            {props.city || props.province ? 
                            <> - {props.city} {props.province ? "("+props.province+")" : null} </>
                            :null}
                        
                        </div>
                    </>
                    :
                    <br/>}
                </div>
            </div>
        </div>
    );
}

export default ItemSearch;