import React from 'react';

let logo = "/static/logo.png"
let qdiner_logo = "/static/qdiner_logo.png"

function HeaderQdiner(props) {

    return (
        <div className="resturant-header">
            <div className="background-orange" style={{textAlign:"left"}}>
                <div className="row col-lg-6 col-md-8 col-sm-10 col-xs-12 nopadding" style={{margin:"0 auto"}}>
                    <div style={{textAlign:"center",width:"100%"}}>
                        <span>   
                            <a href="/">
                                <img src={logo} alt="qdiner" style={{maxWidth:"100%", maxHeight:"40px",marginTop:"5px",zIndex:1000}}/>
                                <img src={qdiner_logo}  style={{maxWidth:"100%", maxHeight:"35px",marginLeft:"5px",marginTop:"10px",zIndex:1000}} alt="qdiner"/>
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderQdiner;