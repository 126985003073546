import React,{useEffect,useState} from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import MenuDetail from './MenuDetail';
import Header from './Header';
import CustomHelmet from '../utils/CustomHelmet';
import { gtbl,onlyUnique,getNameLangByCode,getLastLanguageSelected,compareByPosition } from '../utils/commons';

function Menu(props) {
    const [loading, setLoading] = useState(true)
    const [resturant, setResturant] = useState(null)
    const [menus, setMenus] = useState(null)
    const [menuId, setMenuId] = useState(null)
    const [languages, setlanguages] = useState([])
    const [selectedLanguage, setSelectedLanguage] = useState(getLastLanguageSelected())

    function getMenuById(id){
        let m = menus.find(x=>x.id === Number(menuId)) 
        return m
    }
    function setUserDefaultLang(e){
        if(e.target.value === "all_lang"){
            setSelectedLanguage(null)
            localStorage.removeItem('lang')
        }else{
            setSelectedLanguage(e.target.value.toLowerCase())
            localStorage.setItem('lang',e.target.value.toLowerCase())

        }
    }
    useEffect(() => {
        if(menus && props.match.params.id){
            getMenuById(props.match.params.id)
        }
    }, [menus])
    

    useEffect(() => {
        if(props.location.resturant){
            setResturant(props.location.resturant)
            setMenus(props.location.resturant.menus)
            let listofalllang=[]
            props.location.resturant.menus.forEach(element => {
                listofalllang.push(element.lang.toLowerCase())
            });
            setlanguages(listofalllang.filter(onlyUnique))
            let lastSelectedLang=getLastLanguageSelected()
            if(lastSelectedLang && listofalllang.findIndex(x=>x === lastSelectedLang) !== -1){
                setSelectedLanguage(lastSelectedLang.toLowerCase())
            }else{
                setSelectedLanguage(null)
                localStorage.removeItem("lang")
            }
            setLoading(false)
            setMenuId(props.match.params.id)
        }else{
            axios.get('/api/v1/place/'+props.match.params.resturant_id+'/').then((res)=>{
                setResturant(res.data) 
                setMenus(res.data.menus)
                let listofalllang=[]
                res.data.menus.forEach(element => {
                    listofalllang.push(element.lang.toLowerCase())
                });
                setlanguages(listofalllang.filter(onlyUnique))
                let lastSelectedLang=getLastLanguageSelected()
                if(lastSelectedLang && listofalllang.findIndex(x=>x === lastSelectedLang) !== -1){
                    setSelectedLanguage(lastSelectedLang.toLowerCase())
                }else{
                    setSelectedLanguage(null)
                    localStorage.removeItem("lang")
                }
                setLoading(false)
                setMenuId(props.match.params.id)
            })
        }
    }, [])
    return (
        <>
        {!loading ? 
            <div className="menu-shell">
                {!menuId ?
                    menus.filter((menu)=>{
                        if(menu.isActive === true) return menu
                    }).length > 1 ? 
                    <div>
                        <Header backurl={"/"+resturant.slug} resturant={resturant} homeurl={"/"+resturant.slug} backgroundcolor={""} color={""} image={resturant.image}></Header>
                        <div style={{padding:"15px 0"}}>
                            {languages.length > 1 ?
                            <div className="select-language form-group form-group-sm">
                                <select name="lang" value={selectedLanguage} onChange={(e)=>{setUserDefaultLang(e)}} className=" select-coolname">
                                    <option value={"all_lang"}>{gtbl("all_lang")}</option>
                                    {languages.map((lang,i)=>{
                                        return(
                                        <option key={i} value={lang.toLowerCase()}>{getNameLangByCode(lang)}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            :null}
                            {menus.sort(compareByPosition).filter((menu)=>{
                                if(menu.isActive === true && (selectedLanguage === null || selectedLanguage === menu.lang)) return menu
                            }).map((menu,i)=>{
                                return(
                                    <Link style={{textDecoration:"none"}}  key={i} onClick={()=>{setMenuId(menu.id)}} to={{
                                        pathname: '/'+props.match.params.resturant_id+'/menus/'+menu.id+'/',
                                            resturant:resturant,
                                            homeurl:resturant.slug,
                                            placeid:resturant.id,
                                            slug:resturant.slug
                                    }}>
                                        <div className={ i%2 === 0 ? "background-grey-card background-grey-card-odd": "background-grey-card background-grey-card-pair"}>
                                            <div className="">{menu.title}</div>
                                            <div style={{fontSize:"13px",fontWeight:"100"}}>{menu.subtitle}</div>
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                    </div> 
                    :
                    <MenuDetail placeid={resturant.id} resturant={resturant} slug={resturant.slug}  homeurl={"/"+resturant.slug} image={resturant.image} name={resturant.name} backurl={"/"+resturant.slug} menu={menus[0]}></MenuDetail>
                :
                <div hidden={!menuId}>
                    <MenuDetail placeid={resturant.id} resturant={resturant} slug={resturant.slug} homeurl={"/"+resturant.slug} menu={getMenuById(menuId)} image={resturant.image} name={resturant.name} backurl={"/"+resturant.slug+"/menus"}></MenuDetail>
                </div>
                }
            </div>
        :null}
        </>
    );
}

export default Menu;