import React, { useState,useEffect } from 'react';
import Flows from './Flow';
import Header from './Header';
import CustomHelmet from '../utils/CustomHelmet';
import {gtbl,onlyUnique,getNameLangByCode,getLastLanguageSelected} from '../utils/commons'
import { Fab } from '@material-ui/core';
import axios from 'axios'

function MenuDetail(props) {
    const [showAllergenList, setShowAllergenList] = useState(false)
    const [allergenList, setAllergenList] = useState([])
    const [selectedAllergenList, setSelectedAllergenList] = useState(null)
    const [selectedLanguage, setSelectedLanguage] = useState(null)
    const [languages, setLanguages] = useState([])
   
    function setUserDefaultLang(e){
        if(e.target.value === "all_lang"){
            setSelectedLanguage(null)
            localStorage.removeItem('lang')
        }else{
            setSelectedLanguage(e.target.value.toLowerCase())
            localStorage.setItem('lang',e.target.value.toLowerCase())
        }
        selectListByLang(allergenList)
    }

    function selectListByLang(list){
        let listofalllang=[]
        list.forEach(element => {
            listofalllang.push(element.lang.toLowerCase())
        });
        setLanguages(listofalllang.filter(onlyUnique))
        let lastSelectedLang=getLastLanguageSelected()

        if(lastSelectedLang && listofalllang.findIndex(x=>x === lastSelectedLang) !== -1){
            setSelectedLanguage(lastSelectedLang.toLowerCase())
            let value = list.filter(x=>x.lang.toLowerCase() === lastSelectedLang.toLowerCase())[0]
            setSelectedAllergenList(value)
        }else{
            setSelectedAllergenList(list[0])
        }
    }

    useEffect(() => {
        axios.get('/api/v1/allergen-list/?placeid='+props.placeid).then(res=>{
            setAllergenList(res.data)
            if(res.data.length > 0){
                selectListByLang(res.data)
            }
        })
    }, [])
    
    return (
        <div className="menu-body" style={allergenList.length>0 ? {paddingBottom:"40px"} : null}>
                {/* <CustomHelmet title={props.menu.title} noindex={true}></CustomHelmet> */}
                <Header homeurl={props.homeurl} resturant={props.resturant}  isDashboard={props.isDashboard} backurl={props.backurl}></Header>
                { props.menu ? 
                <>
                    <CustomHelmet title={props.name}></CustomHelmet>
                    {!showAllergenList ?
                        <div>
                            <div className="row menu-contain">
                                <div className="col-12">
                                    <div>{props.menu.title}</div>
                                    <div style={{fontSize:"13px"}}>{props.menu.subtitle}</div>
                                </div>
                            </div>
                            <Flows isDashboard={props.isDashboard} isTastingMenu={props.menu.isTastingMenu} showAll={false} flows={props.menu.flows} currency={props.menu.currency}></Flows>
                            <div>
                                {props.menu.isTastingMenu && props.menu.price_tasting_menu ? 
                                    <div style={{padding:"10px",color:"#6e6a5e",fontStyle:"italic",fontFamily:"Roboto"}}>
                                        <h2>{(parseFloat(props.menu.price_tasting_menu).toFixed(2)).toLocaleString()} {props.menu.currency}</h2>
                                    </div>
                                :null}
                            </div>
                            <div className="notes">
                                {props.menu.notes ? 
                                props.menu.notes.split('\n').map((item, key) => {
                                    return <span key={key}>{item}<br/></span>
                                })
                                :null}
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="menu-contain">
                                <div className="col-12">
                                    {languages.length > 1 ?
                                        <div className="select-language form-group form-group-sm">
                                            <select name="lang" value={selectedLanguage} onChange={(e)=>{setUserDefaultLang(e)}} className="select-coolname">
                                                {languages.map((lang,i)=>{
                                                    return(
                                                    <option key={i} value={lang.toLowerCase()}>{getNameLangByCode(lang)}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    :null}
                                </div>
                                {selectedAllergenList && selectedAllergenList.listAllergens.length > 0 ?
                                    <div className="col-12" style={{textAlign:"left"}}>
                                        <div style={{paddingBottom:"15px"}}>{selectedAllergenList.title}</div>
                                        <div>
                                        {selectedAllergenList.listAllergens.map((allergen,i)=>{
                                            return(
                                                <div key={i} className={i%2===0 ? "item-list" : "item-list-odd"}>
                                                    <b>{allergen.name}</b> - {allergen.description}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                :null}
                                
                            </div>
                        </div>
                        }
                        { selectedAllergenList && allergenList.length > 0 && selectedAllergenList.listAllergens.length>0 ?
                            <Fab className="fab" aria-label="add" onClick={()=>{setShowAllergenList(!showAllergenList)}}>
                                {showAllergenList ? 
                                    <i className="fa fa-window-close" style={{fontSize:"25px"}}></i>
                                    : <i className="fa fa-exclamation-triangle" style={{fontSize:"25px"}}></i>
                                }
                            </Fab>
                        :null}
                </>
                : <div style={{marginTop:"25px"}}> 
                    <h4>{gtbl("no_menu_available")}</h4>
                </div>}
        </div>
    );
}

export default MenuDetail;