import React, { useState, useEffect } from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import axios from 'axios'
import CardSection from './CardSection';
import { gtbl } from '../../utils/commons';

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [client_secret, setClient_secret] = useState(null)
  const [paying, setPaying] = useState(false)

  useEffect(() => {
      let data={
        email:props.email,
        price:props.prezzo
      }
      axios.post('/api/v1/payment/pay/',data).then((res)=>{
        if(!res.data.error){
          setClient_secret(res.data.client_secret)
        }
      })
  }, [props.prezzo])

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setPaying(true)
    const result = await stripe.confirmCardPayment(client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: props.nomecognome,
        },
      }
    });
    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      props.setMessagePaing(result.error.message)

    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        props.pay(result.paymentIntent.id,result.paymentIntent.status,"stripe")
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
    setPaying(false)
  };
  return (
    <form onSubmit={handleSubmit}>
      <CardSection />
      <button className="btn btn-warning" style={{width:"100%", marginTop:"15px"}} disabled={!stripe | !props.isEnabled} >{!paying ? <span>{gtbl("pay")} {props.prezzo}€</span> : gtbl("paying")}</button>
    </form>
  );
}