import React,{useEffect,useState} from 'react';
import axios from 'axios'
import DetailsResturant from './DetailsResturant';
import { Redirect } from 'react-router-dom';


function Resturant(props) {
    const [loading, setLoading] = useState(true)
    const [resturant, setResturant] = useState(null)
    const [redirectTo, setRedirectTo] = useState(false)
    const [backurl, setBackurl] = useState("/search")

    useEffect(() => {
        // console.log(props.location.resturant)
        // if(props.location.resturant){
        //     setResturant(props.location.resturant)
        //     setLoading(false)
        // }else{
            if(props.location.backurl){
                setBackurl(props.location.backurl)
            }
            axios.get('/api/v1/place/'+props.match.params.resturant_id+'/').then((res)=>{
                setResturant(res.data) 
                setLoading(false)
            }).catch(error=>{
                setRedirectTo(true)
            })
        // }
    }, [])

    return (
        <>
        {!loading ? 
            <DetailsResturant slug={props.match.params.resturant_id} resturant={resturant} backurl={backurl} showGoToMenu={true}></DetailsResturant>
        :null}
        {redirectTo ? <Redirect to="/404" /> : null}
        </>
    );
}

export default Resturant;