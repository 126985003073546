
import traduzioniDash from './traduzioniDash.json'
import languages from './languages.json'
import Cookies from 'universal-cookie';

let namepreferred = "preferred-list"

export function expiringAccount(validity_to,date,days=7){
    return (new Date(validity_to).getTime()-(86400000*days))>(date.getTime())
}

export function removeCookieBeforeSave(){
    try {
        const cookies = new Cookies();
        cookies.remove('sessionid', { path: '/'});
    } catch (error) {
            
    }
}

export function getListPreferred(){
    let list = localStorage.getItem(namepreferred)
    if(!list){
        list=[]
        localStorage.setItem(namepreferred,"[]")
    }else{
        list=JSON.parse(list)
    }
    return list 
}
export function addItemToPreferred(item){
    let list=getListPreferred()
    list.push(item)
    localStorage.setItem(namepreferred,JSON.stringify(list))
    return list
}
export function removeItemToPreferred(item){
    let list=getListPreferred()
    for(var i in list){
        if(list[i].u === item.u){
            list.splice(i,1)
            break
        }
    }
    localStorage.setItem(namepreferred,JSON.stringify(list))
    return list
}
export function getPreferredBySlug(slug){
    //TODO: in caso sia cambiato nome o immagine, fare aggiornamento
    return getListPreferred().findIndex(x=>x.u === slug)
}

export function compareByPosition(a, b){
    return a.position-b.position
}

export function gtbl(name){
    let lang="en"
    if(localStorage.getItem("lang")){
        lang=localStorage.getItem("lang")
    }else{
        setLangFromBrowser(navigator.language)
    }
    try {
        return traduzioniDash[name][lang] ? traduzioniDash[name][lang] : traduzioniDash[name]["en"]
    } catch (error) {
        return traduzioniDash[name]["en"]  
    }
}
export function gtbl_list(name){
    let lang="en"
    if(localStorage.getItem("lang")){
        lang=localStorage.getItem("lang")
    }else{
        setLangFromBrowser(navigator.language)
    }
    try {
        return traduzioniDash[name][lang] ? traduzioniDash[name][lang] : traduzioniDash[name]["en"]
    } catch (error) {
        return traduzioniDash[name]["en"]
    }
}

export function setLangFromBrowser(lang){
    let res="en"
    if(lang==="it-IT"){
        localStorage.setItem("lang","it")
        res="it"
    }else{
        localStorage.setItem("lang","en")
        res="en"
    }
    return res
}

export function getLastLanguageSelected(){
    if(localStorage.getItem("lang")){
        return localStorage.getItem("lang")
    }
    return null
}

export function onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
}

export function dayOfWeekAsString(dayIndex) {
    return ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"][dayIndex];
  }
export function checkslug(slug){
    var regexp = /^[a-zA-Z0-9-]+$/;
    if (slug.search(regexp) === -1){ 
        alert("Attenzione la url può contenere solamente lettere numeri e il carattere \"-\"")
        return false 
    }
    return true
}
export function getNameLangByCode(code){
    return languages.find(x=>x.code.toLowerCase()===code.toLowerCase()).name
}

export function downloadQR(canvasid){
    const canvas = document.getElementById(canvasid);
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrmenu.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}
export function formatDate(date,addmonth=0) {
    var d = new Date(date)
    d.setMonth(d.getMonth()+addmonth)

    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    let year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month,year].join('/');
}