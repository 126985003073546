import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router , Route,Switch,Redirect} from 'react-router-dom'

import './index.css';
import './bootstrap.min.css';
import './device.min.css'
import './Sidebar.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import QuattroZeroQuattro from './Components/QuattroZeroQuattro';
import HomePage from './Components/HomePage';
import Resturant from './Components/Resturant';
import Menu from './Components/Menu';
import HomeDash from './Components/Dashboard/HomeDash';
import Dashboard from './Dashboard';
import 'react-notifications/lib/notifications.css';
import MenuDash from './Components/Dashboard/MenuDash';
import Login from './Components/Login';
import Logout from './Components/Logout';
import AllergenDash from './Components/Dashboard/AllergenDash';
import AccountDash from './Components/Dashboard/AccountDash';
import DetailMenuDash from './Components/Dashboard/DetailMenuDash';
import DetailAllergenDash from './Components/Dashboard/DetailAllergenDash';
import Search from './Components/Search';
import ChangePassword from './Components/Dashboard/ChangePassword';
import ConfirmAccount from './Components/Dashboard/ConfirmaAccount';
import PolicyCookie from './Components/Details/PolicyCookie';
import TermAndConditions from './Components/Details/TermAndConditions';

ReactDOM.render(
  <Router>
        <Switch>
            <Route exact path={"/"} render={(props) => <HomePage {...props}/>} />
            <Route exact path={"/c/dashboard"} render={(props)=><Dashboard><HomeDash {...props} /></Dashboard>} />
            <Route exact path={"/c/login"} render={(props)=><Dashboard><Login {...props} /></Dashboard>} />
            <Route exact path={"/c/logout"} render={(props)=><Dashboard><Logout/></Dashboard>} />
            <Route exact path={"/c/changepassword/:recover_id"} render={(props)=><Dashboard><ChangePassword {...props} /></Dashboard>} />
            <Route exact path={"/c/confirmaccount/:confirm_id"} render={(props)=><Dashboard><ConfirmAccount {...props} /></Dashboard>} />
            <Route exact path={"/c/dashboard/menus"} render={(props)=><Dashboard><MenuDash {...props} /></Dashboard>} />
            <Route exact path={"/c/dashboard/menus/:id_menu"} render={(props)=><Dashboard><DetailMenuDash {...props} /></Dashboard>} />
            <Route exact path={"/c/dashboard/account"} render={(props)=><Dashboard><AccountDash {...props} /></Dashboard>} />
            <Route exact path={"/c/dashboard/allergen"} render={(props)=><Dashboard><AllergenDash {...props} /></Dashboard>} />
            <Route exact path={"/c/dashboard/allergen/:id_allergen"} render={(props)=><Dashboard><DetailAllergenDash {...props} /></Dashboard>} />
            <Route exact path="/404" render={(props)=> <QuattroZeroQuattro />} />
            <Route exact path="/termsandconditions"  render = {(props) => <App> <TermAndConditions {...props} /> </App>} />
            <Route exact path="/policycookie"  render = {(props) => <App> <PolicyCookie {...props} /> </App>} />
            <Route exact path={"/search"} render = {(props) => <App> <Search {...props} /> </App>} />
            <Route exact path={"/:resturant_id"} render = {(props) => <App> <Resturant {...props} /> </App>} />
            <Route exact path={"/:resturant_id/menus"} render = {(props) => <App><Menu {...props} /> </App>} />
            <Route exact path={"/:resturant_id/menus/:id"} render = {(props) => <App><Menu {...props} /> </App>} />
            <Redirect to="/404"></Redirect>
        </Switch>
    </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
