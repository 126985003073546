import React, { useState, useEffect, useReducer} from 'react';
import {NotificationManager} from 'react-notifications';
import axios from 'axios'
import DetailsResturant from '../DetailsResturant';
import PlaceContext, { PlaceProvider, PlaceReducer, initialState } from '../Context/PlaceContext';
import { Link } from 'react-router-dom';
import QRCode from "qrcode.react";
import languages from '../../utils/languages.json'
import type_place from '../../utils/type_place.json'
import HeaderQdiner from '../HeaderQdiner';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Sidebar from './Sidebar';
import {gtbl,dayOfWeekAsString,checkslug,downloadQR, removeCookieBeforeSave, expiringAccount} from '../../utils/commons'
import AccountSmall from '../Details/AccountSmall';

//https://github.com/ricardo-ch/react-easy-crop
function HomeDash(props) {
    // const context = useContext(PlaceContext)
    const [state, dispatch] = useReducer(PlaceReducer, initialState)
    const [saving, setSaving] = useState(false)
    const [isLogged, setLogged] = useState(false)
    const [profile, setProfile] = useState(null)
    const [token] = useState(localStorage.getItem('tk'))
    const [default_lang, setDefaultLang] = useState(null)
    const [typology, setTypology] = useState("")
    const [name, setName] = useState(null)
    const [subtitle, setSubtitle] = useState(null)
    const [address, setAddress] = useState(null)
    const [city, setCity] = useState(null)
    const [province, setProvince] = useState(null)
    const [isVenganFriendly, setisVenganFriendly] = useState(false)
    const [isCeliacFriendly, setIsCeliacFriendly] = useState(false)
    const [isVegetarianFriendly, setIsVegetarianFriendly] = useState(false)
    const [phone, setPhone] = useState(null)
    const [prefix_phone, setPrefix_phone] = useState(null)
    const [email, setEmail] = useState(null)
    const [slug, setSlug] = useState(null)
    const [myTimeout, setMyTimeout] = useState(null)
    const [visible_on_search, setVisible_on_search] = useState(true)
    const [origin_slug, setOrigin_slug] = useState(null)
    const [close_for_holiday, setClose_for_holiday] = useState(false)
    const [message_close_for_holiday, setMessage_close_for_holiday] = useState("")
    const [work_days, setWork_days] = useState(null)
    const [imageCrop, setImageCrop] = useState(null) //immagine originale, non si scappa, non deve essere mai modificato,solo in caso di nuovo inserimento
    const [loadedImage, setLoadedImage] = useState(null)
    const [isValidSlug, setIsValidSlug] = useState(true)
    const [image_size, setImageSize] =useState(null)
    const [imageRef, setImageRef] = useState(null)
    const [newImage, setNewImage] = useState(null)
    const [account, setAccount] = useState(null)
    const [crop, setCrop] = useState({
        unit: '%',
        width: 100,
        aspect: 1,
      })

     function onSelectFile(e){
        if (e.target.files && e.target.files.length > 0) {
          const reader = new FileReader();
          reader.addEventListener("load", () =>{
                setLoadedImage(reader.result)
            });
            setLoadedImage(reader.readAsDataURL(e.target.files[0]))
        }
      };

    function showPreviewImage(file_arr_b64){
        setNewImage(file_arr_b64)
        setImageSize(0)
        setProfile({...profile, image_size:0,image:file_arr_b64})
        dispatch({type:"RELOAD_PLACE",payload:{...profile, image:file_arr_b64}})
    }
    /////SEZIONE CROP IMMAGINE 
    function onImageLoaded(image){
        setImageRef(image);
    };

    function onCropComplete(crop){
        makeClientCrop(crop)
    };

    async function makeClientCrop(crop) {
        if (imageRef && crop.width && crop.height) {
            await getCroppedImg(
                imageRef,
                crop,
                "newFile.jpeg"
            );
        }
    }
    
    function  getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
        showPreviewImage(canvas.toDataURL())
      }

    function onCropChange(crop, percentCrop){
        setCrop({...crop});
    };

    function refreshPage(e){
        e.preventDefault()
        if(!expiringAccount(account.validity_to,new Date(),0)){return}
        window.location.reload(false);
    }
    function aggiornaProfilo(e){
        e.preventDefault();
        if(!expiringAccount(account.validity_to,new Date(),0)){return}

        if (checkslug(slug) && isValidSlug){
            var r = true
            if(slug && slug !== origin_slug){
                r = window.confirm("Proseguento verrà modificata la URL , questo invaliderà i tuoi prcedenti QR CODE, vuoi proseguire?");
            }
            if(r && isValidSlug){
                removeCookieBeforeSave()
                setSaving(true)
                let data = {
                    id:profile.id,
                    default_lang:default_lang,
                    name:name,
                    subtitle:subtitle,
                    address:address,
                    isVenganFriendly:isVenganFriendly,
                    isCeliacFriendly:isCeliacFriendly,
                    isVegetarianFriendly:isVegetarianFriendly,
                    phone:phone,
                    prefix_phone:prefix_phone,
                    email:email,
                    slug:slug,
                    visible_on_search:visible_on_search,
                    province:province,
                    city:city,
                    image_size:image_size,
                    image:newImage,
                    typology:typology,
                    message_close_for_holiday:message_close_for_holiday,
                    close_for_holiday:close_for_holiday,
                    work_days:work_days
                }
                axios.put('/api/v1/config/place/', data, {headers:{token:token}}).then((res)=>{
                    NotificationManager.success(gtbl("success_message"));
                    setSaving(false)  
                }).catch(error=>{
                    NotificationManager.error(gtbl("error_message"));
                    setSaving(false)  
                })
            }
        }else{
            alert("Attenzione URL non valida o già in uso")
        }
    }
    function doNothing(e){
        e.preventDefault();
    }

    function changeView(e){
        setProfile({...profile, [e.target.name]:e.target.value})
        dispatch({type:"RELOAD_PLACE",payload:{...profile, [e.target.name]:e.target.value}})
    }
    function changeViewBool(e,value){
        setProfile({...profile, [e.target.name]:value})
        dispatch({type:"RELOAD_PLACE",payload:{...profile, [e.target.name]:value}})
    }
    function changeGridDay(day,value,name){
        let new_work_day= work_days
        for (var i in new_work_day) {
            if (new_work_day[i].day === day) {
                new_work_day[i][name] = value;
               break; //Stop this loop, we found it!
            }
        }
        setWork_days(new_work_day)
        setProfile({...profile, work_days:new_work_day})
        dispatch({type:"RELOAD_PLACE",payload:{...profile, work_days:new_work_day}})
    }

    function enableDisableDat(day,rest_day){
        let new_work_day= work_days
        for (var i in new_work_day) {
            if (new_work_day[i].day === day) {
                new_work_day[i].rest_day = !new_work_day[i].rest_day;
               break; //Stop this loop, we found it!
            }
          }
        setWork_days(new_work_day)
        setProfile({...profile, work_days:new_work_day})
        dispatch({type:"RELOAD_PLACE",payload:{...profile, work_days:new_work_day}})
    }
    // https://github.com/marvelapp/devices.css
    useEffect(() => {   
        let token = localStorage.getItem('tk')
        if(token){
            axios.get('/api/v1/config/place/',{headers:{token:token}}).then((res)=>{
                setProfile(res.data[0])
                setIsValidSlug(true)
                setDefaultLang(res.data[0].default_lang)
                setName(res.data[0].name)
                setSubtitle(res.data[0].subtitle)
                setAddress(res.data[0].address)
                setProvince(res.data[0].province)
                setCity(res.data[0].city)
                setisVenganFriendly(res.data[0].isVenganFriendly)
                setIsCeliacFriendly(res.data[0].isCeliacFriendly)
                setIsVegetarianFriendly(res.data[0].isVegetarianFriendly)
                setPhone(res.data[0].phone)
                setPrefix_phone(res.data[0].prefix_phone)
                setEmail(res.data[0].email)
                setSlug(res.data[0].slug)
                setOrigin_slug(res.data[0].slug)
                setTypology(res.data[0].typology)
                setVisible_on_search(res.data[0].visible_on_search)
                setImageSize(res.data[0].image_size)
                setImageCrop(res.data[0].image)
                setClose_for_holiday(res.data[0].close_for_holiday)
                setMessage_close_for_holiday(res.data[0].message_close_for_holiday)
                setWork_days(res.data[0].work_days)
                setLogged(true)

                axios.get('/api/v1/user/account/', {headers:{token:token}}).then((res)=>{
                    localStorage.setItem("lang",res.data.data.account_lang)
                    setAccount(res.data.data)
                    
                })

            }).catch((error)=>{
                window.location.replace('/c/login');
            })
        }else{
            window.location.replace('/c/login');
        }
    }, [])

    useEffect(() => {
        if(profile){
            if(slug === profile.slug){
                setIsValidSlug(true)
            }else{
                clearTimeout(myTimeout);
                setIsValidSlug(null)
                setMyTimeout(setTimeout(() => {
                    axios.get('/api/v1/place-list/?exists='+slug).then((res)=>{
                        if(res.data.count>0){
                            setIsValidSlug(false)
                        }else{
                            setIsValidSlug(true)
                        }
                    }).catch(error=>{
                        setIsValidSlug(false)
                    })
                },1500)
                )
            }
        }
    }, [slug])

    function changeLanguage(lang){
        localStorage.setItem("lang",lang)
        setLogged(false)
        setLogged(true)
    }

    return (
        <div>
            <HeaderQdiner></HeaderQdiner>
            {isLogged  && account ?
            <>
            <Sidebar></Sidebar>
                <div className="row">
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <div style={{maxWidth:"600px",margin:"0 auto"}}>
                            <div style={{position:"fixed",width:"100%",maxWidth:"600px",backgroundColor:"white",zIndex:"101",top:"50px",paddingTop:"15px"}}>
                                <button disabled={!expiringAccount(account.validity_to,new Date(),0) || saving ? true : false} style={{margin:"5px"}} className="btn btn-info" onClick={(e)=>{aggiornaProfilo(e)}}>
                                <i className="fa fa-save"></i> { !saving? gtbl("save"):gtbl("saving")}</button>
                                <hr/>
                            </div>
                            {account?
                                <div style={{marginTop:"60px"}}>
                                    <AccountSmall changeLanguage={changeLanguage} account={account}></AccountSmall>
                                </div>
                            :null}
                            <h2>{name}</h2>
                            <div className="row">
                                <div className="col-6">
                                { expiringAccount(account.validity_to,new Date(),0) ? 
                                <Link to={{
                                    pathname: '/c/dashboard/menus',
                                        profile:profile
                                    }}><button disabled={!expiringAccount(account.validity_to,new Date(),0) ? true : false}  style={{width:"100%"}} className="btn btn-warning">
                                        <span className={"d-none d-sm-block"}>
                                            <i className="fas fa-list"></i><span > {gtbl("your")}</span> {gtbl("menu").toLowerCase()}
                                        </span>
                                        <span className={"d-block d-sm-none"}>
                                            <i className="fas fa-list"></i> {gtbl("menu")}
                                        </span>
                                    </button></Link>
                                    : <button disabled={!expiringAccount(account.validity_to,new Date(),0) ? true : false}  style={{width:"100%"}} className="btn btn-warning">
                                        <span className={"d-none d-sm-block"}>
                                            <i className="fas fa-list"></i><span > {gtbl("your")}</span> {gtbl("menu").toLowerCase()}
                                        </span>
                                        <span className={"d-block d-sm-none"}>
                                            <i className="fas fa-list"></i> {gtbl("menu")}
                                        </span>
                                    </button>} 
                                </div>
                                <div className="col-6">
                                {expiringAccount(account.validity_to,new Date(),0) ? 
                                    <Link to={{
                                        pathname: '/c/dashboard/allergen',
                                            profile:profile
                                        }}><button disabled={!expiringAccount(account.validity_to,new Date(),0) ? true : false} style={{width:"100%"}} className="btn btn-secondary">
                                            <span className={"d-none d-sm-block"}>
                                             <i className="fas fa-list"></i><span > {gtbl("manage")}</span> {gtbl("allergens").toLowerCase()}
                                            </span>
                                            <span className={"d-block d-sm-none"}>
                                            <i className="fas fa-list"></i> {gtbl("allergens")}
                                            </span>
                                        </button></Link> 
                                    :<button disabled={!expiringAccount(account.validity_to,new Date(),0) ? true : false} style={{width:"100%"}} className="btn btn-secondary">
                                        <span className={"d-none d-sm-block"}>
                                        <i className="fas fa-list"></i><span > {gtbl("manage")}</span> {gtbl("allergens").toLowerCase()}
                                        </span>
                                        <span className={"d-block d-sm-none"}>
                                        <i className="fas fa-list"></i> {gtbl("allergens")}
                                        </span>
                                    </button>
                                    }
                                </div>
                            </div>
                            <div className="col-12" style={{padding:"20px",textAlign:"center"}}>
                                <div style={{textAlign:"center",margin:"0 auto",maxWidth:"250px"}}>
                                    <QRCode 
                                    id="CANVAS_12345678"
                                    level={"H"}
                                    style={{maxWidth:"100%",width:"550px",maxHeight:"650px",height:"100%"}}
                                    size = {512}
                                    includeMargin={false}
                                    value={'https://qdiner.app/'+slug+'/menus'} />
                                </div>
                                <div><span style={{color:"black",cursor:"pointer"}} onClick={()=>downloadQR("CANVAS_12345678")}>
                                    {gtbl("download_qr")}
                                    </span>
                                </div>
                            </div>
                            <form onSubmit={(e)=>{doNothing(e)}}>
                                <div className="row" style={{padding:"15px 0"}}>
                                    {/* <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <button disabled={!expiringAccount(account.validity_to,new Date(),0) || saving ? true : false} style={{width:"100%",margin:"5px"}} className="btn btn-info" onClick={(e)=>{aggiornaProfilo(e)}}>
                                        <i className="fa fa-save"></i> { !saving? gtbl("save"):gtbl("saving")}</button>
                                    </div> */}
                                    {/* <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <button disabled={!expiringAccount(account.validity_to,new Date(),0) ? true : false} style={{width:"100%",margin:"5px"}} className="btn btn-danger" onClick={(e)=>{refreshPage(e)}}>
                                        <i className="fas fa-trash"></i> {gtbl("cancel_modify")}
                                        </button>
                                    </div> */}
                                {/* <hr/> */}
                                </div>
                                <div className="input-group input-group-sm">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" style={{font:"13px"}}>https://qdiner.app/</span>
                                    </div>
                                    <input autoComplete="off"  required type="text" value={slug} onChange={(e)=>{setSlug(e.target.value)}} className="form-control" placeholder="Enter Phone"/>
                                </div>
                                {!isValidSlug ?
                                   <small>{gtbl("checking_url")}</small>
                                : isValidSlug === false  ? 
                                    <small style={{color:"red"}}>{gtbl("url_non_valido")}</small> 
                                :slug !== origin_slug && isValidSlug ?
                                    <small>{gtbl("attenzione_cambio_url")}</small> : null }

                                <div className="form-group">
                                    <label>{gtbl("name")}</label>
                                    <input autoComplete="off"  type="" name="name" value={name} onChange={(e)=>{setName(e.target.value);changeView(e)}} className="form-control" placeholder="Enter Name"/>
                                </div>
                                <div className="form-group">
                                    <label>{gtbl("subtitle")}</label>
                                    <input autoComplete="off"  type="" name="subtitle" value={subtitle} onChange={(e)=>{setSubtitle(e.target.value);changeView(e)}} className="form-control" placeholder="Enter Subtitle"/>
                                </div>
                                <div className="form-group">
                                    <label>{gtbl("default_lang")}</label>
                                    <select name="type_place" value={default_lang} onChange={(e)=>{setDefaultLang(e.target.value)}} className="form-control">
                                        {languages.map((country,i)=>{
                                            return(
                                            <option key={i} value={country.code}>{country.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div style={{paddingBottom:"15px"}}>
                                    <input style={{width:"100%"}} type="file" accept="image/*" className="btn btn-info" onChange={(e)=>{onSelectFile(e)}} />
                                    <div hidden={!loadedImage} className="imagecropper image-crop" style={{height:"400px",textAlign:"center"}}>
                                        <ReactCrop
                                            src={loadedImage}
                                            crop={crop}
                                            onImageLoaded={(e)=>{onImageLoaded(e)}}
                                            onComplete={(e)=>onCropComplete(e)}
                                            onChange={(e)=>onCropChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>{gtbl("email_address")}</label>
                                    <input type="email" name="email" value={email} onChange={(e)=>{setEmail(e.target.value);changeView(e)}} className="form-control" placeholder="Enter email"/>
                                </div>
                                <div className="form-group">
                                    <label>{gtbl("address")}</label>
                                    <input type="" name="address" value={address} onChange={(e)=>{setAddress(e.target.value);changeView(e)}} className="form-control" placeholder="Enter Address"/>
                                </div>
                                <div className="row">
                                    <div className="form-group col-6">
                                        <label>{gtbl("city")}</label>
                                        <input type="" name="city" value={city} onChange={(e)=>{setCity(e.target.value);changeView(e)}} className="form-control" placeholder="Enter City"/>
                                    </div>
                                    <div className="form-group col-6">
                                    <label>{gtbl("province")}</label>
                                        <input type="" name="province" value={province} onChange={(e)=>{setProvince(e.target.value);changeView(e)}} className="form-control" placeholder="Enter Province"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-4">
                                        <label>{gtbl("prefix_phone")}</label>
                                        <input type="text" name="prefix_phone" value={prefix_phone} onChange={(e)=>{setPrefix_phone(e.target.value);changeView(e)}} className="form-control" placeholder="Enter Prefix Phone"/>
                                    </div>
                                    <div className="form-group col-8">
                                        <label>{gtbl("phone")}</label>
                                        <input type="text" name="phone" value={phone} onChange={(e)=>{setPhone(e.target.value);changeView(e)}} className="form-control" placeholder="Enter Phone"/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>{gtbl("typology")}</label>
                                    <select name="typology" value={typology} onChange={(e)=>{setTypology(e.target.value)}} className="form-control">
                                        {type_place.map((type,i)=>{
                                            return(
                                            <option key={i} value={type.code}>{type.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <hr/>
                                <h4>{gtbl("more_info")}</h4>
                                {/* <div className="form-group">
                                    <input autoComplete="off"  type="checkbox" name="isVenganFriendly" checked={isVenganFriendly} onChange={(e)=>{setisVenganFriendly(!isVenganFriendly);changeViewBool(e,!isVenganFriendly)}}/>
                                    <label>{gtbl("vegan_friendly")}</label>
                                </div>
                                <div className="form-group">
                                    <input autoComplete="off"  type="checkbox" name="isVegetarianFriendly" checked={isVegetarianFriendly} onChange={(e)=>{setIsVegetarianFriendly(!isVegetarianFriendly);changeViewBool(e,!isVegetarianFriendly)}} />
                                    <label>{gtbl("vegetarian_friendly")}</label>
                                </div>
                                <div className="form-group">
                                    <input autoComplete="off"  type="checkbox" name="isCeliacFriendly" checked={isCeliacFriendly} onChange={(e)=>{setIsCeliacFriendly(!isCeliacFriendly);changeViewBool(e,!isCeliacFriendly)}}/>
                                    <label>{gtbl("celiac_friendly")}</label>
                                </div> */}
                                <div className="form-group" hidden={true}>
                                    <input autoComplete="off"  type="checkbox" name="visible_on_search" checked={visible_on_search} onChange={(e)=>{setVisible_on_search(!visible_on_search)}} />
                                    <label className="right-checkbox">{gtbl("visible_search")}</label>
                                </div>
                                <div className="form-group">
                                    <input autoComplete="off"  type="checkbox" name="close_for_holiday" checked={close_for_holiday} onChange={(e)=>{setClose_for_holiday(!close_for_holiday);changeViewBool(e,!close_for_holiday)}} />
                                    <label className="right-checkbox">{gtbl("close_for_holidays")}</label>
                                </div>
                                <div hidden={!close_for_holiday} className="form-group">
                                    <label>{gtbl("message_close_for_holiday")}</label>
                                    <textarea className="form-control" style={{width:"100%",height:"150px"}} value={message_close_for_holiday} type="textarea" name="message_close_for_holiday" onChange={(e)=>{setMessage_close_for_holiday(e.target.value);changeView(e)}} />
                                </div>
                                <div style={{border:"1px solid #F8F8F8",padding:"2px solid red"}}>
                                    <h4>{gtbl("working_days")}</h4>
                                    {work_days && work_days.map((wd,i)=>{
                                        return(
                                        <div key={i} className="container-fluid">
                                            <div className="">
                                                <div className="col-12 input-group">
                                                    <label>
                                                    <input autoComplete="off"  className="right-checkbox" type="checkbox" checked={wd.rest_day} onChange={()=>enableDisableDat(wd.day,wd.rest_day)} />
                                                    <b>{gtbl(dayOfWeekAsString(wd.day))}</b></label>
                                                </div>
                                                <div hidden={!wd.rest_day} className="row">
                                                <div className="col-12 form-group">
                                                    <label htmlFor=""><small>{gtbl("name_of_day")} ({gtbl(dayOfWeekAsString(wd.day))})</small></label>
                                                        <input autoComplete="off"  type="text" name="nameOfDay" value={wd.nameOfDay} className="form-control sm" onChange={(e)=>{changeGridDay(wd.day,e.target.value,e.target.name)}} placeholder=""/>
                                                    </div>
                                                    <div className="col-3 form-group">
                                                        <label htmlFor=""><small>{gtbl("start")}</small></label>
                                                        <input autoComplete="off"  type="time" name="start" value={wd.start} className="form-control sm" onChange={(e)=>{changeGridDay(wd.day,e.target.value,e.target.name)}} placeholder=""/>
                                                    </div>
                                                    <div className="col-3 form-group">
                                                        <label htmlFor=""><small>{gtbl("start_pause")}</small></label>
                                                        <input autoComplete="off"  type="time" name="start_pause" value={wd.start_pause} className="form-control sm" onChange={(e)=>{changeGridDay(wd.day,e.target.value,e.target.name)}} placeholder=""/>
                                                    </div>
                                                    <div className="col-3 form-group">
                                                        <label htmlFor=""><small>{gtbl("end_pause")}</small></label>
                                                        <input autoComplete="off"  type="time" name="end_pause" value={wd.end_pause} className="form-control sm" onChange={(e)=>{changeGridDay(wd.day,e.target.value,e.target.name)}} placeholder=""/>
                                                    </div>
                                                    <div className="col-3 form-group">
                                                        <label htmlFor=""><small>{gtbl("end")}</small></label>
                                                        <input autoComplete="off"  type="time"name="end" value={wd.end} className="form-control sm" onChange={(e)=>{changeGridDay(wd.day,e.target.value,e.target.name)}} placeholder=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-4 d-none d-lg-block" style={{textAlign:"center",position:"fixed", right:0}}>
                        <PlaceProvider value={{state, dispatch}}>
                        <div className="marvel-device nexus5">
                            <div className="top-bar"></div>
                            <div className="sleep"></div>
                            <div className="volume"></div>
                            <div className="camera"></div>
                            <div className="screen scrolller" style={{overflowY:"scroll",margin:"0"}}>
                                <DetailsResturant isDashboard={true} resturant={profile} showGoToMenu={false}></DetailsResturant>
                            </div>
                        </div>
                        </PlaceProvider>
                    </div>
                </div>
            </>
            : 
            <div className="text-center">
                <div className="spinner-border text-secondary spinner-bootstrap" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}    
        </div>
    );
}

export default HomeDash;