import React, { useEffect } from 'react';

function Logout(props) {
    useEffect(() => {
        localStorage.removeItem('tk')
        window.location.href="/c/login"
    }, [])
    return (
        <div></div>
    );
}

export default Logout;