import React from 'react';
import { slide as Menu } from "react-burger-menu";

const Sidebar = () => {
    return (
        <Menu>
        <a className="menu-item" href="/">
            Home
        </a>
        <a className="menu-item" href="/c/dashboard/">
            Profile
        </a>
        <a className="menu-item" href="/c/dashboard/account">
            Account
        </a>
        <a className="menu-item" href="/c/logout">
            Logout
        </a>
        </Menu>
    );
};

export default Sidebar;