import React,{useState} from 'react';
import axios from 'axios'
import { formatDate, gtbl, expiringAccount, getLastLanguageSelected} from '../../utils/commons';

function AccountSmall(props) {
    const [account_lang, setaccount_lang] = useState(localStorage.getItem("lang"))

    function changeAccountName(lang){
        let token=localStorage.getItem("tk")
        let data={type:"set_lang",account_lang:lang}
        setaccount_lang(lang)
        localStorage.setItem("lang",lang)
        axios.put('/api/v1/user/account/', data,{headers:{token:token}}).then(res=>{
            props.changeLanguage(lang)
        })
    }

    return (
        <div style={{marginBottom:"15px"}}>
            <div>{gtbl("hi")} {props.account.name && props.account.lastname ? props.account.name:props.account.email} - 
                <span> {expiringAccount(props.account.validity_to,new Date(),0) ? gtbl("validity_until") : gtbl("expired_account")} {formatDate(props.account.validity_to)}</span> 
                {!expiringAccount(props.account.validity_to,new Date()) ? <>
                    <br/> <a style={{color:"red"}} href="/c/dashboard/account"><button type="button" class="btn btn-danger">{gtbl("renew")}</button></a></>
                :null}
                {props.account.name && props.account.lastname ? null: <small> - <a href="/c/dashboard/account">{gtbl("complete_profile_short")}</a></small>}
                <div>
                    <span style={account_lang === "it"?{fontWeight:"bold"}:{cursor:"pointer"}} onClick={()=>changeAccountName("it")}>Ita</span> | <span style={account_lang==="en"?{fontWeight:"bold"}:{cursor:"pointer"}} onClick={()=>changeAccountName("en")}>eng</span>
                </div>
            </div>
        </div>
    );
}

export default AccountSmall;