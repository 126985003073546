import React from 'react';
import {gtbl} from '../utils/commons'

let logo = "/static/logo.png"
let qdiner_logo = "/static/qdiner_logo.png"

function HeaderHomepage(props) {
    return (
        <div className="resturant-header" style={props.isDashboard ? {display:"none"}: null}>
            <div className="background-orange" style={{textAlign:"left"}}>
                <div className="col-lg-6 col-md-8 col-sm-10 co-xs-12 nopadding" style={{margin:"0 auto"}}>
                    <div className="row">
                        <div className="col-6">
                            <a href="/">
                                <img src={logo} alt="qdiner" style={{maxWidth:"100%", maxHeight:"40px",marginTop:"5px",zIndex:1000}}/>
                                <img src={qdiner_logo} style={{width:"100%",maxWidth:"80px", maxHeight:"35px",marginLeft:"5px",marginTop:"10px",zIndex:1000}} alt="qdiner"/>
                            </a>
                        </div>
                        <div hidden={!props.isVisible} className="col-6" style={{textAlign:"right",marginTop:"5px"}}>
                            <span>
                                <a href={props.loginurl} style={{color:"#1a171c"}}>
                                    <i style={{fontSize:"25px", marginTop:"10px"}} className="fas fa-user"></i> {gtbl("singin")}
                                </a>
                            </span>
                            {/* <span onClick={()=>props.showSearch()} style={{borderLeft:"1px solid #4a4e57"}}>
                                <i style={{fontSize:"25px", marginTop:"10px",marginLeft:"15px",color:"#1a171c",cursor:"pointer"}} className="fa fa-search" aria-hidden="true"></i>
                            </span> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderHomepage;