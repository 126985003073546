import React from 'react';
import {NotificationContainer} from 'react-notifications';

function Dashboard(props) {
  return (
    <div className="container-fluid dashboard">
      <div className="col-12" style={{padding:"30px 5px",paddingTop:"90px"}}>
        {props.children}
      </div>
      <NotificationContainer/>
    </div>
  );
}

export default Dashboard;
