import React from 'react';

function App(props) {
  return (
    <div className="center-text">
        <div className="col-lg-6 col-md-8 col-sm-10 co-xs-12 nopadding" style={{margin:"0 auto",paddingBottom:"25px",paddingTop:"50px"}}>
          {props.children}
        </div>
    </div>
  );
}
export default App;
